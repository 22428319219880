import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

type UserType = 'Invitation' | 'Active';

interface User {
  email: string;
  role: string;
  type: UserType;
  mfa_enabled: boolean;
}

type UserServiceRestriction = { [key: string]: string[] };

interface UserManagementState {
  users: User[];
  userServiceRestrictions: UserServiceRestriction;
  loading: boolean;
  lastFetch: number | null;
  adding: boolean;
  changeError: string | null;
  inviting: boolean;
  invitingUsers: User[];
  error: string | null;
}

const initialState: UserManagementState = {
  users: [],
  userServiceRestrictions: {},
  loading: false,
  lastFetch: null,
  adding: false,
  changeError: null,
  inviting: false,
  invitingUsers: [],
  error: null,
};

const slice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    // actions => action handlers

    usersReceived: (userManagement, action) => {
      userManagement.users = action.payload[0];
      userManagement.loading = false;
      userManagement.lastFetch = Date.now();
    },

    usersServiceRestrictionsReceived: (userManagement, action) => {
      for (let restriction of action.payload[0]) {
        if (!userManagement.userServiceRestrictions[restriction.email]) {
          userManagement.userServiceRestrictions[restriction.email] = [];
        }
        userManagement.userServiceRestrictions[restriction.email].push(restriction.service);
      }
    },

    usersAdded: (userManagement, action) => {
      let newUsers = [...userManagement.users];
      for (let user of action.payload[1].payload.data) {
        if (!newUsers.find((u) => u.email === user.email)) {
          newUsers.push(user);
        }
      }
      userManagement.users = newUsers;
      userManagement.invitingUsers = [];
      userManagement.inviting = false;
    },

    userDeleted: (userManagement, action) => {
      const deleted = action.payload[1].payload.id;
      userManagement.users = userManagement.users.filter((s) => s.email !== deleted);
    },

    addInvitation: (userManagement, action) => {
      userManagement.invitingUsers.push(...action.payload);
    },

    toggleInvitation: (userManagement, action) => {
      userManagement.inviting = action.payload;
      userManagement.error = null;
    },

    changeInvitedRole: (userManagement, action) => {
      let user = userManagement.invitingUsers.find((u) => u.email === action.payload.email);
      user!.role = action.payload.role;
    },

    userRoleUpdated: (userManagement, action) => {
      let user = userManagement.users.find((u) => u.email === action.payload[1].payload.data.email);
      user!.role = action.payload[1].payload.data.role;
    },

    errorOccurred: (userManagement, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      userManagement.error = msg;
    },
  },
});

export const {
  usersReceived,
  usersAdded,
  userDeleted,
  errorOccurred,
  addInvitation,
  toggleInvitation,
  changeInvitedRole,
  userRoleUpdated,
  usersServiceRestrictionsReceived,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadUsers = () =>
  apiCallBegan({
    url: '/api/v1/auth/account/users/',
    onSuccess: usersReceived.type,
  });

export const addUsers = (users: User[]) =>
  apiCallBegan({
    url: `/api/v1/users/`,
    method: 'post',
    data: users,
    onSuccess: usersAdded.type,
    onError: errorOccurred.type,
  });

export const updateUser = (u: User) =>
  apiCallBegan({
    url: `/api/v1/update-current-user/`,
    data: u,
    method: 'put',
    onError: errorOccurred.type,
  });

export const updateUserRole = (email: string, role: string) =>
  apiCallBegan({
    url: `/api/v1/update-user-role/`,
    data: { email: email, role: role },
    method: 'put',
    onSuccess: userRoleUpdated.type,
    onError: errorOccurred.type,
  });

export const deleteUser = (email: string) =>
  apiCallBegan({
    id: email,
    url: `/api/v1/delete-user/`,
    data: { email: email },
    method: 'delete',
    onSuccess: userDeleted.type,
  });

export const loadUsersServiceRestrictions = () =>
  apiCallBegan({
    url: '/api/v1/user-service-restrictions',
    onSuccess: usersServiceRestrictionsReceived.type,
  });

export const updateUserServiceRestrictions = (email: string, services: string[]) =>
  apiCallBegan({
    url: '/api/v1/user-service-restrictions/update_multiple/',
    method: 'post',
    data: {
      email: email,
      services: services,
    },
    onSuccess: usersServiceRestrictionsReceived.type,
  });
