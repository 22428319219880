import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface Token {
  id: string;
  name: string;
  expires_at: number;
  created_at: number;
  user: string;
  role: string;
}

interface TokenManagementState {
  tokens: Token[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  generatedToken?: string;
}

const initialState: TokenManagementState = {
  tokens: [],
  loading: false,
  lastFetch: null,
  error: null,
};

const slice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    // actions => action handlers

    tokensReceived: (tokenManagement, action) => {
      tokenManagement.tokens = action.payload[0];
      tokenManagement.loading = false;
      tokenManagement.lastFetch = Date.now();
    },

    tokenAdded: (tokenManagement, action) => {
      tokenManagement.tokens.push(action.payload[0]);
    },

    tokenDeleted: (tokenManagement, action) => {
      const deleted = action.payload[1].payload.id;
      tokenManagement.tokens = tokenManagement.tokens.filter((s) => s.id !== deleted);
    },

    tokenRoleUpdated: (tokenManagement, action) => {
      let token = tokenManagement.tokens.find((t) => t.id === action.payload[1].payload.data.id);
      if (token === undefined) return;
      token.role = action.payload[1].payload.data.role;
      tokenManagement.tokens = tokenManagement.tokens.map((t) => {
        if (t.id === action.payload[1].payload.data.id) {
          return token!;
        } else {
          return t;
        }
      });
    },

    generatedTokenReceived: (tokenManagement, action) => {
      tokenManagement.generatedToken = action.payload[0]['key'];
    },

    errorOccurred: (tokenManagement, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      tokenManagement.error = msg;
    },
  },
});

export const {
  tokensReceived,
  tokenAdded,
  tokenDeleted,
  errorOccurred,
  tokenRoleUpdated,
  generatedTokenReceived,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadTokens = () =>
  apiCallBegan({
    url: '/api/v1/auth/account/tokens/',
    onSuccess: tokensReceived.type,
  });

export const generateToken = () =>
  apiCallBegan({
    url: '/api/v1/auth/account/generate-token/',
    method: 'post',
    onSuccess: generatedTokenReceived.type,
  });

export const addToken = (name: string, key: string, role: string, expires_at: number) =>
  apiCallBegan({
    url: `/api/v1/auth/account/add-token/`,
    method: 'post',
    data: { key: key, name: name, role: role, expires_at: expires_at },
    onSuccess: tokenAdded.type,
    onError: errorOccurred.type,
  });

export const updateTokenRole = (id: string, role: string) =>
  apiCallBegan({
    url: `/api/v1/auth/account/update-token-role/`,
    data: { id: id, role: role },
    method: 'put',
    onSuccess: tokenRoleUpdated.type,
    onError: errorOccurred.type,
  });

export const deleteToken = (id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/auth/account/delete-token/`,
    data: { id: id },
    method: 'delete',
    onSuccess: tokenDeleted.type,
  });
