import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

type BehaviorActionType =
  | 'SET_RESPONSE_HEADER'
  | 'CACHE_TTL'
  | 'REDIRECT_HTTP_TO_HTTPS'
  | 'CACHE_BEHAVIOR'
  | 'BROWSER_CACHE_TTL'
  | 'REDIRECT'
  | 'ORIGIN_CACHE_CONTROL'
  | 'BYPASS_CACHE_ON_COOKIE'
  | 'CACHE_KEY'
  | 'AUTO_MINIFY'
  | 'HOST_HEADER_OVERRIDE'
  | 'SET_CORS_HEADER'
  | 'OVERRIDE_ORIGIN'
  | 'ORIGIN_ERRORS_PASS_THRU'
  | 'FOLLOW_REDIRECTS'
  | 'STATUS_CODE_CACHE'
  | 'GENERATE_PREFLIGHT_RESPONSE'
  | 'STATUS_CODE_BROWSER_CACHE'
  | 'STALE_TTL'
  | 'STREAM_LOGS'
  | 'ALLOWED_METHODS'
  | 'COMPRESSION'
  | 'GENERATE_RESPONSE'
  | 'CACHED_METHODS'
  | 'HTTP_VERSIONS';

type CacheBehaviorValueType = 'CACHE' | 'BYPASS';

interface BehaviorAction {
  id: string;
  type: BehaviorActionType;
  max_ttl: string | null;
  response_header_name: string | null;
  response_header_value: string | null;
  cache_behavior_value: CacheBehaviorValueType;
  redirect_url: string | null;
  origin_cache_control_enabled: boolean | null;
  pattern: string | null;
  cookie: string | null;
  auto_minify: string | null;
  host_header: string | null;
  use_domain_origin: boolean | null;
  origin: string | null;
  enabled: boolean | null;
  cache_key: string | null;
  cors_allow_origin_domain: boolean | null;
  status_code: number | null;
  unified_log_destination: string | null;
  unified_log_sampling_rate: number | null;
  allowed_methods: string | null;
  response_page_path: string | null;
  cached_methods: string | null;
  genId?: number;
  validationError?: string | null;
}

export function validateActions(behavior_actions: BehaviorAction[]) {
  let invalid = false;
  behavior_actions.map((a) => {
    if (a.type === 'BROWSER_CACHE_TTL' || a.type === 'CACHE_TTL') {
      if (!a.max_ttl) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      }
      if (!parseInt(a.max_ttl)) {
        a.validationError = 'TTL should be a valid number';
        invalid = true;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'SET_RESPONSE_HEADER' || a.type === 'SET_CORS_HEADER') {
      if (!a.response_header_name || (!a.response_header_value && !a.cors_allow_origin_domain)) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'CACHE_BEHAVIOR') {
      if (!a.cache_behavior_value) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'HOST_HEADER_OVERRIDE') {
      if (!a.host_header && !a.use_domain_origin) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'OVERRIDE_ORIGIN') {
      if (!a.origin) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'REDIRECT') {
      if (!a.redirect_url) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      }
      if (
        !RegExp(
          '^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$',
        ).test(a.redirect_url)
      ) {
        a.validationError = 'Enter a valid URL';
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'BYPASS_CACHE_ON_COOKIE') {
      if (!a.cookie) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      }
      a.validationError = null;
    } else if (a.type === 'CACHE_KEY') {
      let cacheKey = JSON.parse(a.cache_key!);
      if (cacheKey['query_strings']['type'] === 'whitelist') {
        if (!cacheKey['query_strings']['list'] || !cacheKey['query_strings']['list'].length) {
          a.validationError = 'List should include at least one item';
          invalid = true;
          return null;
        }
      }
      a.validationError = null;
    } else if (a.type === 'STREAM_LOGS') {
      if (!a.unified_log_destination) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else if (
        !a.unified_log_sampling_rate ||
        a.unified_log_sampling_rate < 1 ||
        a.unified_log_sampling_rate > 100
      ) {
        a.validationError = 'Enter percent value';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    }

    return null;
  });
  return !invalid;
}

interface Behavior {
  id: string;
  service: string;
  name: string;
  path_pattern: string;
  behavior_actions: BehaviorAction[];
  is_default: boolean;
}

interface BehaviorState {
  list: Behavior[];
  loading: boolean;
  lastFetch: number | null;
  adding: boolean;
  updating: Behavior | null;
  changeError: string | null;
  invalid: boolean;
  deleted: string[];
  updated: { [key: string]: Behavior };
}

const initialState: BehaviorState = {
  list: [],
  loading: false,
  lastFetch: null,
  adding: false,
  updating: null,
  changeError: null,
  invalid: false,
  deleted: [],
  updated: {},
};

const slice = createSlice({
  name: 'behaviors',
  initialState,
  reducers: {
    // actions => action handlers

    behaviorsReceived: (behaviors, action) => {
      if (behaviors.updating) {
        return;
      }

      behaviors.list = action.payload[0].filter(
        (b: Behavior) => behaviors.deleted.findIndex((d) => d === b.id) === -1,
      );
      behaviors.list = behaviors.list.map((b) => {
        let updated = behaviors.updated[b.id];
        if (updated) {
          return updated;
        }
        return b;
      });

      for (const i in behaviors.list) {
        let actions = behaviors.list[i].behavior_actions;
        for (const i in actions) {
          actions[i].genId = Math.random();
        }
      }
      behaviors.loading = false;
      behaviors.lastFetch = Date.now();
    },

    behaviorsAdded: (behaviors, action) => {
      behaviors.list.push(action.payload[0]);
      behaviors.updating = null;
    },

    behaviorDeleted: (behaviors, action) => {
      const deleted = action.payload[1].payload.id;
      behaviors.list = behaviors.list.filter((s) => {
        return s.id !== deleted;
      });
      behaviors.deleted.push(deleted);
    },

    behaviorUpdated: (behaviors, action) => {
      const index = behaviors.list.findIndex((behavior) => behavior.id === action.payload[0].id);
      behaviors.list[index] = behaviors.updating!;
      behaviors.updated[action.payload[0].id] = behaviors.updating!;
      behaviors.updating = null;
    },

    editUpdatingState: (behaviors, action) => {
      behaviors.updating = action.payload;
      if (behaviors.updating) {
        validateActions(behaviors.updating.behavior_actions);
        behaviors.invalid =
          behaviors.updating &&
          behaviors.updating.behavior_actions.findIndex((a) => a.validationError) !== -1;
        if (behaviors.updating.id) {
          window.location.hash = behaviors.updating.id;
        } else {
          window.location.hash = '';
        }
      }

      behaviors.changeError = '';
    },

    behaviorsChangeStopped: (behaviors, action) => {
      behaviors.adding = false;
      behaviors.updating = null;
      window.location.hash = '';
    },

    behaviorChangeErrorHappened: (behaviors, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        // if (msg)
      }

      behaviors.changeError = msg;
    },

    invalidateForm: (behaviors, action) => {
      behaviors.invalid = action.payload;
    },
  },
});

export const {
  behaviorsReceived,
  behaviorsAdded,
  behaviorDeleted,
  behaviorUpdated,
  editUpdatingState,
  behaviorsChangeStopped,
  behaviorChangeErrorHappened,
  invalidateForm,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadBehaviors = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/`,
    onSuccess: behaviorsReceived.type,
  });

export const addBehavior = (s: string, b: Behavior) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/`,
    method: 'post',
    data: b,
    onSuccess: behaviorsAdded.type,
    onError: behaviorChangeErrorHappened.type,
  });

export const deleteBehavior = (s: string, b: string) =>
  apiCallBegan({
    id: b,
    url: `/api/v1/services/${s}/behaviors/${b}/`,
    method: 'delete',
    onSuccess: behaviorDeleted.type,
  });

export const updateBehavior = (s: string, id: string, b: Behavior) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/behaviors/${id}/`,
    method: 'put',
    data: b,
    onSuccess: behaviorUpdated.type,
    onError: behaviorChangeErrorHappened.type,
  });
