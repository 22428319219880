import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

export interface ProviderTraffic {
  provider: string;
  hits: number;
  bytes: number;
  hitsPercentage: number;
  bytesPercentage: number;
  hitsBucketIndex: number;
  bytesBucketIndex: number;
}

export interface GeoTraffic {
  geo: string;
  providers: Array<ProviderTraffic>;
  totalHits: number;
  totalBytes: number;
  hitsPercentOfWorld: number;
  bytesPercentOfWorld: number;
  hitsBucketIndex: number;
  bytesBucketIndex: number;
}
export interface GeoStats {
  accountID: string;
  data: Array<GeoTraffic>;
  world: GeoTraffic;
}

export interface GeoStatsState {
  geoStats: GeoStats | null;
  startTime: number;
  endTime: number;
  geos: string;
  field: 'hits' | 'bytes';
  error: string | null;
  isUTC: boolean;
}

export interface UpdateFiltersAction {
  startTime: number;
  endTime: number;
  isUTC: boolean;
}

const initialState: GeoStatsState = {
  geoStats: null,
  startTime: new Date().getTime() - 3600000,
  endTime: new Date().getTime(),
  isUTC: false,
  geos: '',
  field: 'hits',
  error: null,
};

const slice = createSlice({
  name: 'geoStats',
  initialState,
  reducers: {
    geoStatsReceived: (stats, action) => {
      stats.geoStats = action.payload[0];
      let totalHits = 0;
      let totalBytes = 0;
      let v = stats.geoStats?.world;
      if (v) {
        v.providers.forEach((p) => {
          totalHits += p.hits;
          totalBytes += p.bytes;
        });
        v.totalHits = totalHits;
        v.totalBytes = totalBytes;
        v.providers.forEach((p) => {
          p.hitsPercentage = Math.round((p.hits / totalHits) * 100);
          p.bytesPercentage = Math.round((p.bytes / totalBytes) * 100);
        });
      }

      if (stats.geoStats) {
        let maxHits = 0;
        let maxBytes = 0;
        let maxHitsPerProvider: any = {};
        let maxBytesPerProvider: any = {};
        stats.geoStats.data.forEach((v) => {
          let totalHits = 0;
          let totalBytes = 0;
          v.providers.forEach((p) => {
            totalHits += p.hits;
            totalBytes += p.bytes;
            if (!maxHitsPerProvider[p.provider] || p.hits > maxHitsPerProvider[p.provider]) {
              maxHitsPerProvider[p.provider] = p.hits;
            }
            if (!maxBytesPerProvider[p.provider] || p.bytes > maxBytesPerProvider[p.provider]) {
              maxBytesPerProvider[p.provider] = p.bytes;
            }
          });
          v.totalHits = totalHits;
          v.totalBytes = totalBytes;
          v.providers.forEach((p) => {
            p.hitsPercentage = Math.round((p.hits / totalHits) * 100);
            p.bytesPercentage = Math.round((p.bytes / totalBytes) * 100);
          });

          if (stats.geoStats) {
            v.hitsPercentOfWorld = Math.round((v.totalHits / stats.geoStats.world.totalHits) * 100);
            v.bytesPercentOfWorld = Math.round(
              (v.totalBytes / stats.geoStats.world.totalBytes) * 100,
            );
          }

          if (v.totalHits > maxHits) {
            maxHits = v.totalHits;
          }

          if (v.totalBytes > maxBytes) {
            maxBytes = v.totalBytes;
          }
        });

        stats.geoStats.data.sort((a, b): number => {
          return a.totalHits - b.totalHits;
        });

        stats.geoStats.data.forEach((a, index) => {
          if (stats.geoStats) {
            let bucket = (100 * a.totalHits) / maxHits / 20;
            if (bucket > 4) {
              bucket = 4;
            }
            a.hitsBucketIndex = Math.round(bucket);
          }
        });

        stats.geoStats.data.sort((a, b): number => {
          return a.totalBytes - b.totalBytes;
        });
        stats.geoStats.data.forEach((a, index) => {
          if (stats.geoStats) {
            let bucket = (a.totalBytes * 100) / maxBytes / 20;
            if (bucket > 4) {
              bucket = 4;
            }
            a.bytesBucketIndex = Math.round(bucket);
          }
        });

        for (let providerName of Object.keys(maxHitsPerProvider)) {
          stats.geoStats.data.sort((a, b): number => {
            let providerA = a.providers.find((p) => p.provider === providerName);
            let providerB = b.providers.find((p) => p.provider === providerName);
            if (!providerA) {
              return -1;
            }

            if (!providerB) {
              return 1;
            }
            return providerA.hits - providerB.hits;
          });

          stats.geoStats.data.forEach((a, index) => {
            let provider = a.providers.find((p) => p.provider === providerName);
            if (!provider) {
              return;
            }
            let bucket = (100 * provider.hits) / maxHitsPerProvider[providerName] / 20;
            if (bucket > 4) {
              bucket = 4;
            }
            provider.hitsBucketIndex = Math.round(bucket);
          });

          stats.geoStats.data.sort((a, b): number => {
            let providerA = a.providers.find((p) => p.provider === providerName);
            let providerB = b.providers.find((p) => p.provider === providerName);
            if (!providerA) {
              return -1;
            }

            if (!providerB) {
              return 1;
            }
            return providerA.bytes - providerB.bytes;
          });

          stats.geoStats.data.forEach((a) => {
            let provider = a.providers.find((p) => p.provider === providerName);
            if (!provider) {
              return;
            }
            let bucket = (100 * provider.bytes) / maxBytesPerProvider[providerName] / 20;
            if (bucket > 4) {
              bucket = 4;
            }
            provider.bytesBucketIndex = Math.round(bucket);
          });
        }
      }
    },

    updateFilters: (stats, action: PayloadAction<UpdateFiltersAction>) => {
      stats.startTime = action.payload.startTime;
      stats.endTime = action.payload.endTime;
      stats.isUTC = action.payload.isUTC;
    },

    onError: (geoStats, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (typeof msg != 'string' && !(msg instanceof String)) {
          msg = JSON.stringify(msg[Object.keys(msg)[0]]);
        }
      }

      geoStats.error = msg;
    },
  },
});

export const { geoStatsReceived, onError, updateFilters } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadGeoStats = (s: string, startTime: number, endTime: number, geos: string) =>
  apiCallBegan({
    url: `/api/v2/traffic/overtime/geo/${s}?startTime=${startTime}&endTime=${endTime}&geos=${geos}`,
    onSuccess: geoStatsReceived.type,
  });
