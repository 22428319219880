import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

export enum CommunicationChannelType {
  Slack = 'slack',
  Email = 'email',
  Admin = 'admin',
}

export interface CommunicationChannel {
  id: string;
  account: string;
  name: string;
  channel_type: CommunicationChannelType;
  channel_destination: string;
  channel_credentials: string;
}

interface CommunicationChannelState {
  channels: CommunicationChannel[];
  updating: CommunicationChannel | null;
  error: string | null;
}

const initialState: CommunicationChannelState = {
  channels: [],
  updating: null,
  error: null,
};

const slice = createSlice({
  name: 'communicationChannels',
  initialState,
  reducers: {
    channelsReceived: (state, action) => {
      state.channels = action.payload[0];
    },
    editUpdatingState: (state, action) => {
      state.updating = action.payload;
      state.error = null;
    },
    channelAdded: (state, action) => {
      state.channels.push(action.payload[0]);
      state.updating = null;
      state.error = null;
    },
    channelUpdated: (state, action) => {
      state.channels = state.channels.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      state.updating = null;
      state.error = null;
    },
    channelDeleted: (state, action) => {
      const deleted = action.payload[1].payload.id;
      state.channels = state.channels.filter((s) => {
        return s.id !== deleted;
      });
    },
    channelChangeErrorHappened: (state, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }
      state.error = msg;
    },
  },
});

export const {
  channelsReceived,
  editUpdatingState,
  channelAdded,
  channelUpdated,
  channelDeleted,
  channelChangeErrorHappened,
} = slice.actions;

export default slice.reducer;

export const loadChannels = () =>
  apiCallBegan({
    url: '/api/v1/alert-channels/',
    onSuccess: channelsReceived.type,
  });

export const addChannel = (a: CommunicationChannel) =>
  apiCallBegan({
    url: '/api/v1/alert-channels/',
    method: 'post',
    data: a,
    onSuccess: channelAdded.type,
    onError: channelChangeErrorHappened.type,
  });

export const updateChannel = (a: CommunicationChannel) =>
  apiCallBegan({
    id: a.id,
    url: `/api/v1/alert-channels/${a.id}/`,
    method: 'put',
    data: a,
    onSuccess: channelUpdated.type,
    onError: channelChangeErrorHappened.type,
  });

export const deleteChannel = (id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/alert-channels/${id}/`,
    method: 'delete',
    onSuccess: channelDeleted.type,
  });
