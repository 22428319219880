import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface ComputeRoute {
  id: string;
  domain: string;
  path: string;
}

interface Compute {
  id: string;
  name: string;
  service: string;
  request_code: string;
  response_code: string;
  compute_routes: ComputeRoute[];
}

type SortByType = 'name' | 'request_code' | 'response_code';

interface ComputeState {
  list: Compute[];
  filtered: Compute[];
  filterByName: string;
  updating: Compute | null;
  sortBy: SortByType;
  desc: boolean;
  error: string | null;
}

const initialState: ComputeState = {
  list: [],
  filtered: [],
  filterByName: '',
  updating: null,
  sortBy: 'name',
  desc: false,
  error: null,
};

function filter(compute: ComputeState): Compute[] {
  let filtered = compute.list;
  if (compute.filterByName) {
    filtered = compute.list.filter(
      (c) => c.name.toLowerCase().indexOf(compute.filterByName.toLowerCase()) !== -1,
    );
  }
  filtered.sort((a, b) => {
    let first = a;
    let second = b;
    if (compute.desc) {
      first = b;
      second = a;
    }
    if (compute.sortBy === 'request_code' || compute.sortBy === 'response_code') {
      return first[compute.sortBy] ? -1 : 1;
    }

    return first[compute.sortBy].localeCompare(second[compute.sortBy]);
  });
  return filtered;
}

const slice = createSlice({
  name: 'compute',
  initialState,
  reducers: {
    // actions => action handlers

    computesReceived: (compute, action) => {
      compute.list = action.payload[0];
      compute.filtered = filter(compute);
    },

    computeAdded: (compute, action) => {
      compute.list.push(action.payload[0]);
      compute.filtered = filter(compute);
      compute.updating = null;
    },

    computeDeleted: (compute, action) => {
      const deleted = action.payload[1].payload.id;
      compute.list = compute.list.filter((p) => p.id !== deleted);
      compute.filtered = filter(compute);
    },

    updateUpdatingState: (compute, action) => {
      compute.updating = action.payload;
    },

    filterByName: (compute, action) => {
      compute.filterByName = action.payload;
      compute.filtered = filter(compute);
    },

    sort: (compute, action) => {
      if (compute.sortBy === action.payload) {
        compute.desc = !compute.desc;
      }
      compute.sortBy = action.payload;
      compute.filtered = filter(compute);
    },

    computeUpdated: (compute, action) => {
      const index = compute.list.findIndex((c) => c.id === action.payload[0].id);
      compute.list[index] = action.payload[0];
      compute.updating = null;
      compute.filtered = filter(compute);
    },

    onError: (compute, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (typeof msg != 'string' && !(msg instanceof String)) {
          msg = JSON.stringify(msg[Object.keys(msg)[0]]);
        }
      }

      compute.error = msg;
    },
  },
});

export const {
  computesReceived,
  computeAdded,
  computeDeleted,
  updateUpdatingState,
  filterByName,
  sort,
  onError,
  computeUpdated,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadComputes = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/compute/`,
    onSuccess: computesReceived.type,
  });

export const addCompute = (s: string, c: Compute) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/compute/`,
    method: 'post',
    data: c,
    onError: onError.type,
    onSuccess: computeAdded.type,
  });

export const updateCompute = (s: string, c: Compute, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/compute/${id}/`,
    method: 'put',
    data: c,
    onError: onError.type,
    onSuccess: computeUpdated.type,
  });

export const deleteCompute = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/compute/${id}/`,
    method: 'delete',
    onError: onError.type,
    onSuccess: computeDeleted.type,
  });
