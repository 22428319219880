import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { mergeDeep } from '../../views/utils';
import { GraphData } from '../model/trafficStatsModels';

function adjust(granularity, metrics) {
  switch (granularity) {
    case 'HOUR':
      metrics.hits /= 60;
      metrics.bytes /= 60;
      break;
    case 'DAY':
      metrics.hits /= 1440;
      metrics.bytes /= 1440;
      break;
  }
}

export function createEmptyGraphData(providers, isPercentage) {
  return new GraphData(providers, isPercentage);
}

function prepareGraphData(
  providers,
  timestamps,
  combinedSeries,
  filterStatusCodes,
  filter4xx,
  filter3xx,
  filterHttpVersion,
  granularity,
) {
  let graphNames = [
    'hits',
    'bytes',
    'cachedHits',
    'cachedBytes',
    'errors',
    'statusCodesHits',
    'statusCodesBytes',
    '3xxHits',
    '3xxBytes',
    '4xxHits',
    '4xxBytes',
    'httpVersionHits',
    'httpVersionBytes',
  ];

  let percentageGraphs = {
    httpVersionHits: true,
    httpVersionBytes: true,
    statusCodesHits: true,
    statusCodesBytes: true,
    cachedHits: true,
    cachedBytes: true,
    errors: true,
    '3xxHits': true,
    '3xxBytes': true,
    '4xxHits': true,
    '4xxBytes': true,
  };

  let graphData = {};

  for (let provider of providers) {
    for (let timestamp of timestamps) {
      for (const [serviceId, points] of Object.entries(combinedSeries)) {
        let serviceData = graphData[serviceId];
        if (!serviceData) {
          serviceData = {};
          graphData[serviceId] = serviceData;
          for (let graphName of graphNames) {
            serviceData[graphName] = createEmptyGraphData(providers, percentageGraphs[graphName]);
          }
        }

        let point = points[timestamp];
        if (!point) {
          continue;
        }

        let basic = point.basic;
        if (!basic) {
          continue;
        }

        let providerBasic = basic[provider];

        if (!providerBasic) {
          continue;
        }

        serviceData['hits'].addPoint(
          timestamp,
          providerBasic.hits,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['bytes'].addPoint(
          timestamp,
          providerBasic.bytes,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['cachedHits'].addPoint(
          timestamp,
          providerBasic.cachedHitsPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          true,
        );

        serviceData['cachedBytes'].addPoint(
          timestamp,
          providerBasic.cachedBytesPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          true,
        );

        serviceData['errors'].addPoint(
          timestamp,
          providerBasic.errorsPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          true,
        );

        let statusCodes = null;
        if (point.extended?.statusCodes) {
          statusCodes = point.extended?.statusCodes[provider];
        }
        if (statusCodes) {
          let statusCode = statusCodes[filterStatusCodes];
          if (statusCode) {
            let allStatusCodeBytes = 0;
            let allStatusCodeHits = 0;

            for (const [code, metrics] of Object.entries(statusCodes)) {
              let codeParsed = parseInt(code);
              if (codeParsed && codeParsed < 100) {
                allStatusCodeHits += metrics.hits;
                allStatusCodeBytes += metrics.bytes;
              }
            }

            serviceData['statusCodesHits'].addPoint(
              timestamp,
              Math.round(((statusCode.hits * 1.0) / allStatusCodeHits) * 100),
              provider,
              granularity,
              1,
              true,
            );

            serviceData['statusCodesBytes'].addPoint(
              timestamp,
              Math.round(((statusCode.bytes * 1.0) / allStatusCodeBytes) * 100),
              provider,
              granularity,
              1,
              true,
            );
          }

          let statusCode3xx = statusCodes[filter3xx];
          if (statusCode3xx) {
            serviceData['3xxHits'].graphLines[provider].push(0);
            let total3 = statusCodes['3'];
            if (total3) {
              if (total3.hits > 0) {
                serviceData['3xxHits'].addPoint(
                  timestamp,
                  Math.round(((statusCode3xx.hits * 1.0) / total3.hits) * 100),
                  provider,
                  granularity,
                  1,
                  true,
                );
              }

              if (total3.bytes > 0) {
                serviceData['3xxBytes'].addPoint(
                  timestamp,
                  Math.round(((statusCode3xx.bytes * 1.0) / total3.bytes) * 100),
                  provider,
                  granularity,
                  1,
                  true,
                );
              }
            }
          }

          let statusCode4xx = statusCodes[filter4xx];
          if (statusCode4xx) {
            let total4 = statusCodes['4'];
            if (total4) {
              if (total4.hits > 0) {
                serviceData['4xxHits'].addPoint(
                  timestamp,
                  Math.round(((statusCode4xx.hits * 1.0) / total4.hits) * 100),
                  provider,
                  granularity,
                  1,
                  true,
                );
              }

              if (total4.bytes > 0) {
                serviceData['4xxBytes'].addPoint(
                  timestamp,
                  Math.round(((statusCode4xx.bytes * 1.0) / total4.bytes) * 100),
                  provider,
                  granularity,
                  1,
                  true,
                );
              }
            }
          }
        }

        if (!point.extended?.httpVersion) {
          continue;
        }

        let httpVersion = point.extended.httpVersion[provider];
        if (!httpVersion) {
          continue;
        }

        let allVersionsHits = 0;
        let allVersionsBytes = 0;

        for (let metrics of Object.values(httpVersion)) {
          allVersionsHits += metrics.hits;
          allVersionsBytes += metrics.bytes;
        }

        let currentVersion = httpVersion[filterHttpVersion];
        if (!currentVersion) {
          continue;
        }

        serviceData.httpVersionHits.addPoint(
          timestamp,
          Math.round(((currentVersion.hits * 1.0) / allVersionsHits) * 100),
          provider,
          granularity,
          1,
          true,
        );

        serviceData.httpVersionBytes.addPoint(
          timestamp,
          Math.round(((currentVersion.bytes * 1.0) / allVersionsBytes) * 100),
          provider,
          granularity,
          1,
          true,
        );
      }
    }
  }

  for (let provider of providers) {
    for (let serviceId of Object.keys(combinedSeries)) {
      for (let graphName of graphNames) {
        let graph = graphData[serviceId][graphName];
        graph.addTimestamps(timestamps);
      }
    }
  }

  for (let provider of providers) {
    for (let serviceId of Object.keys(combinedSeries)) {
      for (let graphName of graphNames) {
        let graph = graphData[serviceId][graphName];
        graph.prepare();
      }
    }
  }

  return graphData;
}

const slice = createSlice({
  name: 'serviceTraffic2',
  initialState: {
    servicesTraffic: {},
    aggregatedHits: {},
    lastHourTraffic: {},
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
    filterByGeo: null,
    filterByStatusCode: '2',
    filterBy4xx: '404',
    filterBy3xx: '301',
    filterByHttpVersion: 'HTTP/1.1',
    combinedSeries: {},
    totalCombined: {},
    timestamps: [],
    totalTimestamps: [],
    allProviders: [],
    graphData: {},
    currentGranularity: 'MINUTE',
  },
  reducers: {
    trafficReceived: (serviceTraffic, action) => {
      let combinedSeries = {};
      if (serviceTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTraffic.timestamps = [];
      }
      serviceTraffic.currentGranularity = action.payload[0].granularity;
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              basic: {},
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          for (let providerMetrics of point.metrics) {
            adjust(action.payload[0].granularity, providerMetrics.metrics);
            serviceTraffic.allProviders.push(providerMetrics.providerName);
            let basic = perTimestamp['basic'];
            basic[providerMetrics.providerName] = providerMetrics.metrics;
            serviceTraffic.timestamps.push(timestamp);
          }
        }
      }
      serviceTraffic.timestamps = Array.from(new Set(serviceTraffic.timestamps));
      serviceTraffic.allProviders = Array.from(new Set(serviceTraffic.allProviders));
      mergeDeep(serviceTraffic.combinedSeries, combinedSeries);
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    totalTrafficReceived: (serviceTraffic, action) => {
      let combinedSeries = {};
      if (serviceTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTraffic.timestamps = [];
      }
      serviceTraffic.currentGranularity = action.payload[0].granularity;
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              basic: {},
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          for (let providerMetrics of point.metrics) {
            adjust(action.payload[0].granularity, providerMetrics.metrics);
            perTimestamp['basic'] = providerMetrics.metrics;
            serviceTraffic.totalTimestamps.push(timestamp);
          }
        }
      }
      serviceTraffic.totalTimestamps = Array.from(new Set(serviceTraffic.totalTimestamps));
      mergeDeep(serviceTraffic.totalCombined, combinedSeries);
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    statusCodesReceived: (serviceTraffic, action) => {
      if (serviceTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTraffic.timestamps = [];
      }
      serviceTraffic.currentGranularity = action.payload[0].granularity;
      let xx4Types = [400, 401, 403, 404];
      let xx3Types = [301, 302, 304];
      let combinedSeries = {};
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }
        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              extended: {
                statusCodes: {},
              },
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }
          for (let providerMetrics of point.metrics) {
            serviceTraffic.allProviders.push(providerMetrics.providerName);
            let statusCodes = perTimestamp.extended.statusCodes;
            let statusCode = parseInt(providerMetrics.advancedMetricValue);
            if (!statusCodes[providerMetrics.providerName]) {
              statusCodes[providerMetrics.providerName] = {};
            }
            statusCodes[providerMetrics.providerName][Math.floor(statusCode / 100) + ''] =
              providerMetrics.metrics;
            if (statusCode >= 400 && statusCode < 500) {
              let found = false;
              for (let xx4 of xx4Types) {
                if (statusCode === xx4) {
                  statusCodes[providerMetrics.providerName][xx4] = providerMetrics.metrics;
                  found = true;
                }
              }
              if (!found) {
                statusCodes[providerMetrics.providerName]['other_4xx'] = providerMetrics.metrics;
              }
            } else if (statusCode >= 300 && statusCode < 400) {
              for (let xx3 of xx3Types) {
                let found = false;
                if (statusCode === xx3) {
                  statusCodes[providerMetrics.providerName][xx3] = providerMetrics.metrics;
                  found = true;
                }
                if (!found) {
                  statusCodes[providerMetrics.providerName]['other_3xx'] = providerMetrics.metrics;
                }
              }
            }

            serviceTraffic.timestamps.push(timestamp);
            adjust(action.payload[0].granularity, providerMetrics.metrics);
          }
        }
      }
      serviceTraffic.timestamps = Array.from(new Set(serviceTraffic.timestamps));
      serviceTraffic.allProviders = Array.from(new Set(serviceTraffic.allProviders));
      mergeDeep(serviceTraffic.combinedSeries, combinedSeries);
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    httpVersionReceived: (serviceTraffic, action) => {
      serviceTraffic.currentGranularity = action.payload[0].granularity;
      let combinedSeries = {};

      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              extended: {
                httpVersion: {},
              },
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          for (let providerMetrics of point.metrics) {
            serviceTraffic.allProviders.push(providerMetrics.providerName);
            let httpVersion = perTimestamp.extended.httpVersion;
            if (!httpVersion[providerMetrics.providerName]) {
              httpVersion[providerMetrics.providerName] = {};
            }
            httpVersion[providerMetrics.providerName][providerMetrics.advancedMetricValue] =
              providerMetrics.metrics;
            serviceTraffic.timestamps.push(timestamp);
            adjust(action.payload[0].granularity, providerMetrics.metrics);
          }
        }
      }
      serviceTraffic.timestamps = Array.from(new Set(serviceTraffic.timestamps));
      serviceTraffic.allProviders = Array.from(new Set(serviceTraffic.allProviders));
      mergeDeep(serviceTraffic.combinedSeries, combinedSeries);
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    updateRange2: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.startTime = action.payload.startTime;
      serviceTraffic.endTime = action.payload.endTime;
    },

    onError: (serviceTraffic, action) => {
      let msg = action.payload[0];

      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      serviceTraffic.error = msg;
    },

    updateGeo: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.filterByGeo = action.payload;
    },

    modifyExtendedStatsFinished: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.startTime = new Date().getTime() - 3600000;
      serviceTraffic.endTime = new Date().getTime();
    },

    chooseStatusCode: (serviceTraffic, action) => {
      serviceTraffic.filterByStatusCode = action.payload;
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    choose3xx: (serviceTraffic, action) => {
      serviceTraffic.filterBy3xx = action.payload;
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    choose4xx: (serviceTraffic, action) => {
      serviceTraffic.filterBy4xx = action.payload;
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },

    chooseHTTPVersion: (serviceTraffic, action) => {
      serviceTraffic.filterByHttpVersion = action.payload;
      serviceTraffic.graphData = prepareGraphData(
        serviceTraffic.allProviders,
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.currentGranularity,
      );
    },
  },
});

export const {
  trafficReceived,
  updateRange2,
  onError,
  updateGeo,
  statusCodesReceived,
  httpVersionReceived,
  modifyExtendedStatsFinished,
  choose3xx,
  chooseStatusCode,
  choose4xx,
  chooseHTTPVersion,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadTraffic2 = (service, startTime, endTime, geo) => {
  let geoParam = '';
  if (geo !== null && geo !== 'world') {
    geoParam = '&geo=' + geo;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam,
    onSuccess: trafficReceived.type,
    onError: onError.type,
  });
};

export const loadStatusCodes = (service, startTime, endTime, geo) => {
  let geoParam = '';
  if (geo !== null && geo !== 'world') {
    geoParam = '&geo=' + geo;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam +
      '&advancedMetricName=status_code',
    onSuccess: statusCodesReceived.type,
    onError: onError.type,
  });
};

export const loadHttpVersion = (service, startTime, endTime, geo) => {
  let geoParam = '';
  if (geo !== null && geo !== 'world') {
    geoParam = '&geo=' + geo;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam +
      '&advancedMetricName=http_version',
    onSuccess: httpVersionReceived.type,
    onError: onError.type,
  });
};

export const modifyExtendedStats = (service, enable) => {
  return apiCallBegan({
    url: `/api/v1/services/${service}/modify_extended_stats/`,
    method: 'PUT',
    data: {
      enable: enable,
    },
    onSuccess: modifyExtendedStatsFinished.type,
    onError: onError.type,
  });
};
