import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'topStats',
  initialState: {
    list: [],
    listByBytes: [],
    byErrors: [],
    byErrorBytes: [],
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
    error: null,
    perProvider: {},
  },
  reducers: {
    // actions => action handlers

    topStatsReceived: (topStats, action) => {
      if (
        action.payload[1].payload.provider &&
        action.payload[1].payload.provider !== 'all_providers'
      ) {
        if (action.payload[1].payload.topBy === 'hits') {
          topStats.perProvider[action.payload[1].payload.provider].list = action.payload[0];
        } else {
          topStats.perProvider[action.payload[1].payload.provider].listByBytes = action.payload[0];
        }
      } else {
        if (action.payload[1].payload.topBy === 'hits') {
          topStats.list = action.payload[0];
        } else {
          topStats.listByBytes = action.payload[0];
        }
      }
    },

    topByErrorsReceived: (topStats, action) => {
      if (action.payload[1].payload.topBy === 'errors') {
        topStats.byErrors = action.payload[0];
      } else {
        topStats.byErrorBytes = action.payload[0];
      }
    },

    onError: (topStats, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (typeof msg != 'string' && !(msg instanceof String)) {
          msg = JSON.stringify(msg[Object.keys(msg)[0]]);
        }
      }

      topStats.error = msg;
    },

    updateRange: (topStats, action) => {
      topStats.startTime = action.payload.startTime;
      topStats.endTime = action.payload.endTime;
    },
  },
});

export const { topStatsReceived, topByErrorsReceived, onError, updateRange } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadTopStats = (s, startTime, endTime, topBy, provider) => {
  return apiCallBegan({
    url: `/api/v1/top_stats/${s}?startTime=${startTime}&endTime=${endTime}&topBy=${topBy}`,
    topBy: topBy,
    provider: provider,
    onSuccess: topStatsReceived.type,
  });
};

export const loadTopByErrorsStats = (s, startTime, endTime, topBy) =>
  apiCallBegan({
    url: `/api/v1/top_stats/${s}?startTime=${startTime}&endTime=${endTime}&topBy=${topBy}`,
    topBy: topBy,
    onSuccess: topByErrorsReceived.type,
  });
