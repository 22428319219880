import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'dashboard',
  initialState: {
    sidebarShow: true,
    sidebarUnfoldable: false,
    graphsInterval: 'Month',
    service: '',
  },
  reducers: {
    setSidebarShow: (dashboard, action) => {
      dashboard.sidebarShow = action.payload;
    },
    setSidebarUnfoldable: (dashboard, action) => {
      dashboard.sidebarUnfoldable = action.payload;
    },
    setGraphsInterval: (dashboard, action) => {
      dashboard.graphsInterval = action.payload;
    },
    setService: (dashboard, action) => {
      dashboard.service = action.payload;
    },
  },
});

export const { setSidebarShow, setSidebarUnfoldable, setGraphsInterval, setService } =
  slice.actions;
export default slice.reducer;
