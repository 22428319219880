import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import crs from './crs_rules';
import JSONbigint from 'json-bigint';

const RULES_PER_PAGE = 10;

interface ManagedRuleGroup {
  id?: string;
  rule_prefix: string;
  enabled: boolean;
}

interface OverrideManagedRule {
  id?: string;
  rule_id: number;
  enabled: boolean;
}

interface ManagedRuleSet {
  id: string;
  waf: string;
  name: string;
  display_name: string;
  enabled: boolean;
  groups: ManagedRuleGroup[];
  paranoia_level: number;
  block: boolean;
  overrides: OverrideManagedRule[];
  anomaly_threshold: number;
}

interface Waf {
  id: string;
  service: string;
  enabled: boolean;
}

type CustomRuleActionType = 'log' | 'deny' | 'allow' | 'bypass';

type FiledType =
  | 'REQUEST_HEADERS:User-Agent'
  | 'REQUEST_METHOD'
  | 'REQUEST_URI_RAW'
  | 'PATH_INFO'
  | 'ARGS_GET'
  | 'REQUEST_HEADERS:Referer'
  | 'REQUEST_HEADERS:Host'
  | 'REQUEST_HEADERS:X-IORIVER-ASN'
  | 'REQUEST_HEADERS:X-IORIVER-COUNTRY'
  | 'REMOTE_ADDR'
  | '';

type OperatorType = '@streq' | '!@streq' | '@contains' | '!@contains' | '@rx' | '!@rx' | '';

interface Condition {
  id?: string;
  field: FiledType;
  operator: OperatorType;
  value: string;
  isLast?: boolean;
}

interface ConditionGroup {
  id?: string;
  conditions: Condition[];
}

type WafType = 'WAF' | 'RateLimit';

interface Rule {
  id: string;
  waf: string;
  action: CustomRuleActionType;
  condition_groups: ConditionGroup[];
  name: string;
  enabled: boolean;
  type: WafType;
  time_window_seconds: number;
  num_of_requests: number;
  duration_seconds: number;
  internal_id: number;
  priority: number;
  service_uid: string;
}

interface WafRulesFilterProps {
  customRulesFilterText: string;
  customRulesSortField: string;
  customRulesSortDesc: boolean;
  filterCustomRulesByAction: string;
  filterCustomRulesByStatus: string;
  customRulesPage: number;
}

interface WafState {
  managedRuleSets: ManagedRuleSet[];
  waf: Waf | null;
  customRules: Rule[];
  rateLimitRules: Rule[];
  filteredCustomRules: Rule[];
  beforeReorder: Rule[] | null;
  filteredRateLimitRules: Rule[];
  wafRulesFilterProps: WafRulesFilterProps;
  rateLimitRulesFilterProps: WafRulesFilterProps;
  error: string | null;
  updating: Rule | null;
  updatingManagedRuleSet: ManagedRuleSet | null;
  updatingManagedRules: typeof crs;
  filteredManagedRules: typeof crs;
  managedRuleGroups: { crs: { rule_prefix: string; name: string }[]; ioriverRS: [] } | {};
  managedRulesFilterText: string;
  managedRulesFilterById: string;
  filterManagedRulesByStatus: string;
  managedRulesPage: number;
  updatedCustomRules: { [key: string]: Rule };
  updatedRateLimitRules: { [key: string]: Rule };
  updatedManagedRules: { [key: string]: ManagedRuleSet };
  updatingTimeWindowFormatted: { value: number | null; units: number };
  updatingDurationFormatted: { value: number | null; units: number };
  pendingSwitchedWAFRules: Rule[];
  pendingSwitchedRateLimitRules: Rule[];
  pendingSwitchedManagedRulesets: ManagedRuleSet[];
}

export interface UpdatePayload {
  groupIndex: number;
  conditionIndex: number;
  field: string;
  value: string;
}

function setAttr<T, K extends keyof T, V extends T[K]>(obj: T, attr: K, value: V) {
  obj[attr as keyof T] = value;
}

function filterCustomRules(filterProps: WafRulesFilterProps, customRules: Rule[]) {
  let filtered = customRules;
  if (filterProps.customRulesFilterText) {
    filtered = filtered.filter(
      (c) => c.name.toLowerCase().indexOf(filterProps.customRulesFilterText.toLowerCase()) !== -1,
    );
  }

  if (filterProps.filterCustomRulesByAction) {
    filtered = filtered.filter((c) => c.action === filterProps.filterCustomRulesByAction);
  }

  if (filterProps.filterCustomRulesByStatus) {
    filtered = filtered.filter(
      (c) => c.enabled + '' === filterProps.filterCustomRulesByStatus + '',
    );
  }

  let start = (filterProps.customRulesPage - 1) * RULES_PER_PAGE;
  filtered = filtered.slice(start, start + RULES_PER_PAGE);
  return filtered;
}

function filterManagedRules(waf: WafState) {
  let filtered = waf.updatingManagedRules;
  filtered = filtered.filter((r) => {
    let paranoiaTag = r.tags.find((t) => t.indexOf('paranoia-level') !== -1);
    if (paranoiaTag) {
      let level = parseInt(paranoiaTag.split('/')[1]);
      return level <= waf.updatingManagedRuleSet!.paranoia_level;
    }
    return true;
  });
  if (waf.managedRulesFilterText) {
    filtered = filtered.filter((c) => {
      let msg = c.msg;
      if (!msg) {
        msg = c.rules_file_name;
      }
      return msg.toLowerCase().indexOf(waf.managedRulesFilterText.toLowerCase()) !== -1;
    });
  }

  if (waf.filterManagedRulesByStatus) {
    filtered = filtered.filter((c) => {
      if (waf.filterManagedRulesByStatus === 'true') {
        let override = waf.updatingManagedRuleSet!.overrides.find(
          (o) => o.rule_id === parseInt(c.id),
        );
        return !override || override.enabled === true;
      } else {
        let override = waf.updatingManagedRuleSet!.overrides.find(
          (o) => o.rule_id === parseInt(c.id),
        );
        return override && override.enabled === false;
      }
    });
  }

  if (waf.managedRulesFilterById) {
    filtered = filtered.filter((c) => {
      return c.id === waf.managedRulesFilterById;
    });
  }

  return filtered;
}

function convertSecondsToFormatted(secs: number) {
  if (secs % 3600 === 0) {
    return { value: secs / 3600, units: 3600 };
  } else if (secs % 60 === 0) {
    return { value: secs / 60, units: 60 };
  } else {
    return { value: secs, units: 1 };
  }
}

function upsertPendingRule(waf: WafState, rule: Rule) {
  if (rule.type === 'WAF') {
    let index = waf.pendingSwitchedWAFRules.findIndex((r) => r.id === rule.id);
    let existingRule = null;

    if (waf.beforeReorder) {
      existingRule = waf.beforeReorder.find((r) => r.id === rule.id);
    }
    if (index === -1) {
      if (
        !existingRule ||
        existingRule.enabled !== rule.enabled ||
        existingRule.priority !== rule.priority
      ) {
        waf.pendingSwitchedWAFRules.push(rule);
      }
    } else {
      if (
        existingRule &&
        rule.enabled === existingRule.enabled &&
        rule.priority === existingRule.priority
      ) {
        waf.pendingSwitchedWAFRules.splice(index, 1);
      } else {
        waf.pendingSwitchedWAFRules.splice(index, 1, rule);
      }
    }
  } else {
    let index = waf.pendingSwitchedRateLimitRules.findIndex((r) => r.id === rule.id);
    if (index === -1) {
      waf.pendingSwitchedRateLimitRules.push(rule);
    } else {
      waf.pendingSwitchedRateLimitRules.splice(index, 1);
    }
  }
}

function upsertPendingRuleset(waf: WafState, ruleset: ManagedRuleSet) {
  let index = waf.pendingSwitchedManagedRulesets.findIndex((r) => r.id === ruleset.id);
  let existingRuleset = waf.managedRuleSets.find((r) => r.id === ruleset.id);

  if (index === -1) {
    if (existingRuleset?.enabled !== ruleset.enabled || existingRuleset.block !== ruleset.block) {
      waf.pendingSwitchedManagedRulesets.push(ruleset);
    }
  } else {
    if (existingRuleset?.enabled === ruleset.enabled && existingRuleset.block === ruleset.block) {
      waf.pendingSwitchedManagedRulesets.splice(index, 1);
    } else {
      waf.pendingSwitchedManagedRulesets.splice(index, 1, ruleset);
    }
  }
}

const initialState: WafState = {
  managedRuleSets: [],
  waf: null,
  customRules: [],
  rateLimitRules: [],
  filteredCustomRules: [],
  beforeReorder: null,
  filteredRateLimitRules: [],
  wafRulesFilterProps: {
    customRulesFilterText: '',
    customRulesSortField: 'name',
    customRulesSortDesc: false,
    filterCustomRulesByAction: '',
    filterCustomRulesByStatus: '',
    customRulesPage: 1,
  },
  rateLimitRulesFilterProps: {
    customRulesFilterText: '',
    customRulesSortField: 'name',
    customRulesSortDesc: false,
    filterCustomRulesByAction: '',
    filterCustomRulesByStatus: '',
    customRulesPage: 1,
  },
  error: null,
  updating: null,
  updatingManagedRuleSet: null,
  updatingManagedRules: crs,
  filteredManagedRules: [],
  managedRuleGroups: {},
  managedRulesFilterText: '',
  managedRulesFilterById: '',
  filterManagedRulesByStatus: '',
  managedRulesPage: 1,
  updatedCustomRules: {},
  updatedRateLimitRules: {},
  updatedManagedRules: {},
  updatingTimeWindowFormatted: {
    value: null,
    units: 1,
  },
  updatingDurationFormatted: {
    value: null,
    units: 1,
  },
  pendingSwitchedWAFRules: [],
  pendingSwitchedRateLimitRules: [],
  pendingSwitchedManagedRulesets: [],
};

const slice = createSlice({
  name: 'waf',
  initialState,
  reducers: {
    // actions => action handlers

    wafReceived: (waf, action) => {
      waf.waf = action.payload[0];
    },

    managedRuleSetsReceived: (waf, action) => {
      waf.managedRuleSets = action.payload[0];
      waf.managedRuleSets = waf.managedRuleSets.map((b) => {
        let updated = waf.updatedManagedRules[b.id];
        if (updated) {
          return updated;
        }
        return b;
      });
    },

    customRulesReceived: (waf, action) => {
      let rules = action.payload[0];
      rules = rules.map((b: Rule) => {
        let updated = waf.updatedCustomRules[b.id];
        if (updated) {
          return updated;
        }
        return b;
      });
      waf.customRules = rules.filter((r: Rule) => !r.type || r.type === 'WAF');
      waf.rateLimitRules = rules.filter((r: Rule) => r.type === 'RateLimit');
      waf.filteredCustomRules = filterCustomRules(waf.wafRulesFilterProps, waf.customRules);
      waf.filteredRateLimitRules = filterCustomRules(
        waf.rateLimitRulesFilterProps,
        waf.rateLimitRules,
      );
    },

    customRuleAdded: (waf, action) => {
      if (action.payload[0].type === 'WAF') {
        waf.customRules.push(action.payload[0]);
      } else {
        waf.rateLimitRules.push(action.payload[0]);
      }
      waf.filteredCustomRules = filterCustomRules(waf.wafRulesFilterProps, waf.customRules);
      waf.filteredRateLimitRules = filterCustomRules(
        waf.rateLimitRulesFilterProps,
        waf.rateLimitRules,
      );
      waf.updating = null;
    },

    customRuleUpdated: (waf, action) => {
      let rules = waf.customRules;
      let updatedRules = waf.updatedCustomRules;
      if (action.payload[1].payload.data.type === 'RateLimit') {
        rules = waf.rateLimitRules;
        updatedRules = waf.updatedRateLimitRules;
      }

      const index = rules.findIndex((rule) => rule.id === action.payload[1].payload.data.id);
      if (waf.updating) {
        rules[index] = waf.updating;
      } else {
        rules[index] = action.payload[1].payload.data;
      }

      updatedRules[rules[index].id] = rules[index];

      waf.filteredCustomRules = filterCustomRules(waf.wafRulesFilterProps, waf.customRules);
      waf.filteredRateLimitRules = filterCustomRules(
        waf.rateLimitRulesFilterProps,
        waf.rateLimitRules,
      );
      waf.updating = null;
    },

    customRuleDeleted: (waf, action) => {
      const deleted = action.payload[1].payload.id;
      waf.customRules = waf.customRules.filter((p) => p.id !== deleted);
      waf.rateLimitRules = waf.rateLimitRules.filter((p) => p.id !== deleted);
      waf.filteredCustomRules = filterCustomRules(waf.wafRulesFilterProps, waf.customRules);
      waf.filteredRateLimitRules = filterCustomRules(
        waf.rateLimitRulesFilterProps,
        waf.rateLimitRules,
      );
    },

    managedRuleSetUpdated: (waf, action) => {
      const index = waf.managedRuleSets.findIndex((ruleSet) => ruleSet.id === action.payload[0].id);
      if (waf.updatingManagedRuleSet) {
        waf.managedRuleSets[index] = waf.updatingManagedRuleSet;
      } else {
        waf.managedRuleSets[index] = action.payload[1].payload.data;
      }
      waf.updatedManagedRules[waf.managedRuleSets[index].id] = waf.managedRuleSets[index];
      waf.updatingManagedRuleSet = null;
      waf.pendingSwitchedManagedRulesets = [];
    },
    managedRuleSetsUpdated: (waf, action) => {
      for (let rs of waf.pendingSwitchedManagedRulesets) {
        waf.updatedManagedRules[rs.id] = rs;
        let m = waf.managedRuleSets.find((m) => m.id === rs.id);
        if (m) m.enabled = rs.enabled;
      }

      waf.updatingManagedRuleSet = null;
      waf.pendingSwitchedManagedRulesets = [];
    },

    onError: (waf, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
          if (waf.updating && waf.updating.hasOwnProperty(Object.keys(resp.data)[0])) {
            msg += ' - ' + Object.keys(resp.data)[0];
          }
        }
      }

      waf.error = msg;
    },

    updateCondition: (waf, action) => {
      const payload = action.payload as UpdatePayload;
      let groupIndex = payload.groupIndex;
      let conditionIndex = payload.conditionIndex;
      let field = payload.field;
      setAttr(
        waf.updating!.condition_groups[groupIndex].conditions[conditionIndex],
        field as keyof Condition,
        payload.value,
      );
    },

    updateEditingField: (waf, action) => {
      setAttr(waf.updating!, action.payload.field, action.payload.value);
    },

    updateRateLimitField: (waf, action) => {
      if (action.payload.type === 'time_window') {
        if (action.payload.units) {
          waf.updatingTimeWindowFormatted.units = action.payload.units;
        } else {
          waf.updatingTimeWindowFormatted.value = action.payload.value;
        }
        waf.updating!.time_window_seconds =
          waf.updatingTimeWindowFormatted.value! * waf.updatingTimeWindowFormatted.units;
      } else {
        if (action.payload.units) {
          waf.updatingDurationFormatted.units = action.payload.units;
        } else {
          waf.updatingDurationFormatted.value = action.payload.value;
        }
        waf.updating!.duration_seconds =
          waf.updatingDurationFormatted.value! * waf.updatingDurationFormatted.units;
      }
    },

    addNewConditionGroup: (waf, action) => {
      let currentLast = waf.updating!.condition_groups[waf.updating!.condition_groups.length - 1];
      currentLast.conditions[currentLast.conditions.length - 1].isLast = false;
      waf.updating!.condition_groups.push({
        conditions: [
          {
            field: '',
            operator: '',
            value: '',
            isLast: true,
          },
        ],
      });
    },

    addNewCondition: (waf, action) => {
      let group = waf.updating!.condition_groups[waf.updating!.condition_groups.length - 1];
      group.conditions[group.conditions.length - 1].isLast = false;
      waf.updating!.condition_groups[waf.updating!.condition_groups.length - 1].conditions.push({
        field: '',
        operator: '',
        value: '',
        isLast: true,
      });
    },

    updateEditing: (waf, action) => {
      waf.updating = action.payload;
      if (!action.payload) {
        return;
      }

      if (action.payload.type === 'RateLimit' && action.payload.id) {
        waf.updatingTimeWindowFormatted = convertSecondsToFormatted(
          action.payload.time_window_seconds,
        );
        waf.updatingDurationFormatted = convertSecondsToFormatted(action.payload.duration_seconds);
      } else {
        waf.updatingTimeWindowFormatted = {
          value: null,
          units: 1,
        };
        waf.updatingDurationFormatted = {
          value: null,
          units: 1,
        };
      }
    },

    updateEditingManagedRuleSet: (waf, action) => {
      waf.updatingManagedRuleSet = action.payload;
      if (waf.updatingManagedRuleSet) {
        waf.filteredManagedRules = filterManagedRules(waf);
      }
    },

    rulesFiltered: (waf, action) => {
      if (action.payload.type === 'WAF') {
        setAttr(waf.wafRulesFilterProps, action.payload.fieldName, action.payload.value);
        if (
          action.payload.fieldName === 'customRulesSortField' &&
          action.payload.value === waf.wafRulesFilterProps.customRulesSortField
        ) {
          waf.wafRulesFilterProps.customRulesSortDesc =
            !waf.wafRulesFilterProps.customRulesSortDesc;
        }
        waf.filteredCustomRules = filterCustomRules(waf.wafRulesFilterProps, waf.customRules);
      } else {
        setAttr(waf.rateLimitRulesFilterProps, action.payload.fieldName, action.payload.value);
        waf.filteredRateLimitRules = filterCustomRules(
          waf.rateLimitRulesFilterProps,
          waf.rateLimitRules,
        );

        if (
          action.payload.fieldName === 'customRulesSortField' &&
          action.payload.value === waf.rateLimitRulesFilterProps.customRulesSortField
        ) {
          waf.rateLimitRulesFilterProps.customRulesSortDesc =
            !waf.rateLimitRulesFilterProps.customRulesSortDesc;
        }
      }
    },

    managedRulesFiltered: (waf, action) => {
      waf.managedRulesFilterText = action.payload;
      waf.filteredManagedRules = filterManagedRules(waf);
    },
    managedRulesFilteredById: (waf, action) => {
      waf.managedRulesFilterById = action.payload;
      waf.filteredManagedRules = filterManagedRules(waf);
    },

    managedRulesFilteredByStatus: (waf, action) => {
      waf.filterManagedRulesByStatus = action.payload;
      waf.filteredManagedRules = filterManagedRules(waf);
    },

    managedRuleOverridden: (waf, action) => {
      let ruleId = parseInt(action.payload.rule_id);
      let override = waf.updatingManagedRuleSet!.overrides.find((o) => o.rule_id === ruleId);
      if (!override) {
        override = {
          rule_id: ruleId,
          enabled: action.payload.enabled,
        };
        waf.updatingManagedRuleSet!.overrides.push(override);
      } else {
        override.rule_id = ruleId;
        override.enabled = action.payload.enabled;
      }
      waf.filteredManagedRules = filterManagedRules(waf);
    },

    managedRuleGroupOverridden: (waf, action) => {
      let rulePrefix = action.payload.rule_prefix;
      let override = waf.updatingManagedRuleSet!.groups.find((o) => o.rule_prefix === rulePrefix);
      if (!override) {
        override = {
          rule_prefix: rulePrefix,
          enabled: action.payload.enabled,
        };
        waf.updatingManagedRuleSet!.groups.push(override);
      } else {
        override.rule_prefix = rulePrefix;
        override.enabled = action.payload.enabled;
      }
      waf.filteredManagedRules = filterManagedRules(waf);
    },

    paginateManagedRules: (waf, action) => {
      waf.managedRulesPage = action.payload;
      waf.filteredManagedRules = filterManagedRules(waf);
    },

    addPendingRule: (waf, action) => {
      let rule = { ...action.payload.rule };
      rule['enabled'] = action.payload.enabled;
      upsertPendingRule(waf, rule);
    },

    addPendingRuleset: (waf, action) => {
      let ruleset = { ...action.payload };
      ruleset['enabled'] = action.payload.enabled;
      ruleset['block'] = action.payload.block;
      upsertPendingRuleset(waf, ruleset);
    },

    customRulesUpdated: (waf, action) => {
      let rules = waf.customRules;
      let updatedRules = waf.updatedCustomRules;
      if (action.payload[1].payload.data.rules[0].type === 'RateLimit') {
        rules = waf.rateLimitRules;
        updatedRules = waf.updatedRateLimitRules;
      }
      action.payload[1].payload.data.rules.map((r: Rule) => {
        const index = rules.findIndex((rule) => rule.id === r.id);
        rules[index] = r;
        updatedRules[rules[index].id] = rules[index];
        return null;
      });

      waf.filteredCustomRules = filterCustomRules(waf.wafRulesFilterProps, waf.customRules);
      waf.filteredRateLimitRules = filterCustomRules(
        waf.rateLimitRulesFilterProps,
        waf.rateLimitRules,
      );
      waf.pendingSwitchedWAFRules = [];
    },

    ruleGroupsReceived: (waf, action) => {
      waf.managedRuleGroups = action.payload[0];
    },

    clearError: (waf, action) => {
      waf.error = null;
    },

    reorderCustomRules: (waf, action) => {
      if (waf.beforeReorder === null) {
        waf.beforeReorder = waf.filteredCustomRules;
      }
      let newRules = JSONbigint.parse(JSON.stringify(action.payload));
      let priority = 1;
      for (let c of newRules) {
        c['priority'] = priority;
        priority++;
        upsertPendingRule(waf, c);
      }
      waf.filteredCustomRules = newRules;
    },

    discardReorder: (waf, action) => {
      waf.filteredCustomRules = waf.beforeReorder!;
      waf.beforeReorder = null;
      for (let c of waf.filteredCustomRules) {
        upsertPendingRule(waf, c);
      }
    },

    deleteCondition: (waf, action) => {
      let group = waf.updating!.condition_groups[action.payload.conditionGroupIndex];
      if (group.conditions[action.payload.conditionIndex].isLast) {
        if (group.conditions.length > 1) {
          group.conditions[action.payload.conditionIndex - 1].isLast = true;
        } else {
          let prevGroup = waf.updating!.condition_groups[action.payload.conditionGroupIndex - 1];
          prevGroup.conditions[prevGroup.conditions.length - 1].isLast = true;
        }
      }
      group.conditions.splice(action.payload.conditionIndex, 1);
      if (group.conditions.length === 0) {
        waf.updating!.condition_groups.splice(waf.updating!.condition_groups.length - 1, 1);
      }
    },
  },
});

export const {
  wafReceived,
  managedRuleSetsReceived,
  customRulesReceived,
  customRuleAdded,
  customRuleUpdated,
  customRuleDeleted,
  onError,
  updateCondition,
  updateEditingField,
  addNewCondition,
  addNewConditionGroup,
  updateEditing,
  rulesFiltered,
  managedRuleSetUpdated,
  updateEditingManagedRuleSet,
  managedRulesFiltered,
  managedRulesFilteredById,
  managedRulesFilteredByStatus,
  managedRuleOverridden,
  paginateManagedRules,
  updateRateLimitField,
  customRulesUpdated,
  addPendingRule,
  addPendingRuleset,
  ruleGroupsReceived,
  managedRuleGroupOverridden,
  clearError,
  reorderCustomRules,
  discardReorder,
  deleteCondition,
  managedRuleSetsUpdated,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadWaf = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/waf/`,
    onSuccess: wafReceived.type,
  });

export const loadManagedRuleSets = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/waf-managed-rulesets/`,
    onSuccess: managedRuleSetsReceived.type,
  });

export const updateManagedRuleSet = (s: string, id: string, r: ManagedRuleSet) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/waf-managed-rulesets/${id}/`,
    method: 'put',
    data: r,
    onSuccess: managedRuleSetUpdated.type,
    onError: onError.type,
  });

export const loadCustomRules = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/waf-custom-rules/`,
    onSuccess: customRulesReceived.type,
  });

export const addCustomRule = (s: string, r: Rule) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/waf-custom-rules/`,
    method: 'post',
    data: r,
    onSuccess: customRuleAdded.type,
    onError: onError.type,
  });

export const updateCustomRule = (s: string, id: string, r: Rule) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/waf-custom-rules/${id}/`,
    method: 'put',
    data: r,
    onSuccess: customRuleUpdated.type,
    onError: onError.type,
  });

export const updateMultiple = (s: string, rules: Rule[]) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/waf-custom-rules/update_multiple/`,
    method: 'put',
    data: {
      rules: rules,
      service: s,
    },
    onSuccess: customRulesUpdated.type,
    onError: onError.type,
  });

export const updateMultipleRulesets = (s: string, rules: ManagedRuleSet) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/waf-managed-rulesets/update_multiple/`,
    method: 'put',
    data: {
      rulesets: rules,
      service: s,
    },
    onSuccess: managedRuleSetsUpdated.type,
    onError: onError.type,
  });

export const deleteCustomRule = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/waf-custom-rules/${id}/`,
    method: 'delete',
    onSuccess: customRuleDeleted.type,
    onError: onError.type,
  });

export const loadManagedRuleGroups = () =>
  apiCallBegan({
    url: `/api/v1/waf-rule-groups/`,
    method: 'get',
    onSuccess: ruleGroupsReceived.type,
    onError: onError.type,
  });
