import moment from 'moment';
import { GraphData, Summary, TopEntry } from '../../../store/model/trafficStatsModels';

function prependZero(number) {
  return ('0' + number).slice(-2);
}

export function granularityFromRange(fromTimestamp, toTimestamp) {
  if (toTimestamp > new Date().getTime()) {
    toTimestamp = new Date().getTime();
  }
  let rangeSizeMinutes = (toTimestamp - fromTimestamp) / 60000;
  if (rangeSizeMinutes > 7 * 24 * 60) {
    return 'Month';
  }

  if (rangeSizeMinutes >= 24 * 60) {
    return 'Day';
  }

  return 'Hour';
}

export function pointTrafficByGranularity(perMinute, granularity, numMinutes = 1) {
  if (granularity === 'Hour') {
    return perMinute * numMinutes;
  }

  if (granularity === 'Day') {
    return perMinute * 60;
  }

  if (granularity === 'Week') {
    return perMinute * 60 * 24;
  }

  if (granularity === 'Month') {
    return perMinute * 60 * 24;
  }

  if (granularity === 'Year') {
    return perMinute * 60 * 24 * 30;
  }
}

export function pointTrafficByServerGranularity(perMinute, granularity, numMinutes = 1) {
  if (granularity === 'MINUTE') {
    return perMinute * numMinutes;
  }

  if (granularity === 'HOUR') {
    return perMinute * 60;
  }

  if (granularity === 'DAY') {
    return perMinute * 60 * 24;
  }
}

export const timeIntervalsConfig = {
  Hour: {
    time: 60,
    timeunit: 'minute',
    secsInBucket: 5 * 60,
    numBuckets: 12,
    timestampToLable: (t, isUTC) => {
      let m = moment(t * 1000);
      if (isUTC) {
        m = m.utc();
      }
      return prependZero(m.hour()) + ':' + prependZero(m.minute());
    },
  },

  Day: {
    time: 24,
    timeunit: 'hour',
    secsInBucket: 60 * 60,
    numBuckets: 24,
    timestampToLable: (t, isUTC) => {
      let date = moment(t * 1000);
      if (isUTC) {
        date = date.utc();
      }
      return (
        date.format('DD/MM') + ' ' + prependZero(date.hour()) + ':' + prependZero(date.minute())
      );
    },
  },

  Week: {
    time: 24,
    timeunit: 'hour',
    secsInBucket: 60 * 60,
    numBuckets: 24,
    timestampToLable: (t, isUTC) => {
      let date = moment(t * 1000);
      if (isUTC) {
        date = date.utc();
      }
      return prependZero(date.format('DD/MM'));
    },
  },

  Month: {
    time: 24 * 30,
    timeunit: 'hour',
    secsInBucket: 24 * 60 * 60,
    numBuckets: 30,
    timestampToLable: (t, isUTC) => {
      let m = moment(t * 1000);
      if (isUTC) {
        m = m.utc();
      }
      return m.format('DD/MM');
    },
  },

  Year: {
    time: 24 * 30 * 12,
    timeunit: 'hour',
    secsInBucket: 30 * 24 * 60 * 60,
    numBuckets: 12,
    timestampToLable: (t, isUTC) => {
      let m = moment(t * 1000);
      if (isUTC) {
        m = m.utc();
      }
      return m.format('MM/YYYY');
    },
  },
};

// returns an array which maps a timestamp bucket to a bucket number
export function getTimestampMappings(timeInterval) {
  const currentTime = Math.floor(Date.now() / 1000);
  const todayTimestamp =
    Math.floor(currentTime / timeInterval.secsInBucket) * timeInterval.secsInBucket;

  let dates = {};

  for (let i = 0; i < timeInterval.numBuckets; i++) {
    // map between the timestamp and the index of the timestamp buckets (hour/day/month)
    dates[todayTimestamp - i * timeInterval.secsInBucket] = timeInterval.numBuckets - 1 - i;
  }

  return dates;
}

// for year we need special mappings because we don't have constant intervals between months (some months have 30, 31 days, etc)
// this logic should be replaced once we implement dedicated 'year' api on the server side
export function getYearMappings() {
  const currentDate = new Date(Date.now());
  let mappings = {};

  for (let i = 0; i < 12; i++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const timestamp = date / 1000;
    mappings[timestamp] = 12 - 1 - i;
  }

  return mappings;
}

export function getLablesConfig() {
  return {
    cf: {
      name: 'Cloudflare',
      style: '#E7B403',
      color: '#E7B403',
      badgeColor: '#E7B403',
    },
    fs: {
      name: 'Fastly',
      style: '#FF3D3D',
      color: '#FF3D3D',
      badgeColor: '#FF3D3D',
    },
    cfrnt: {
      name: 'CloudFront',
      style: '#00A854',
      color: '#00A854',
      badgeColor: '#00A854',
    },
    azcdn: {
      name: 'Azure CDN',
      style: '#9A6F00',
      color: '#9A6F00',
      badgeColor: '#9A6F00',
    },
    akamai: {
      name: 'Akamai',
      style: '#0267FF',
      color: '#0267FF',
      badgeColor: '#0267FF',
    },
    gcore: {
      name: 'Gcore',
      style: '#000000',
      color: '#000000',
      badgeColor: '#000000',
    },
    limelight: {
      name: 'Limelight',
      style: '#000000',
      color: '#000000',
      badgeColor: '#000000',
    },
    edgio: {
      name: 'Edgio',
      style: '#810096',
      color: '#810096',
      badgeColor: '#810096',
    },
    cdnetworks: {
      name: 'CDNetworks',
      style: '#810096',
      color: '#810096',
      badgeColor: '#810096',
    },
    googlecloudcdn: {
      name: 'CDNetworks',
      style: '#810096',
      color: '#810096',
      badgeColor: '#810096',
    },
    other: {
      name: 'Other',
      style: '#07A399',
      color: '#07A399',
      badgeColor: '#07A399',
    },
    ioriver: {
      name: 'IORiver',
      style: '#0029ff',
      color: '#0029ff',
      badgeColor: '#0029ff',
    },
  };
}

export function getTick(label, round, bytes) {
  let formatted = formatValue(label, round, bytes);
  return formatted[0] + formatted[1];
}

export function formatValue(label, round, bytes, multiplier) {
  let suffix = '';
  if (bytes) {
    suffix = 'B';
  }
  if (!multiplier) {
    multiplier = 1;
  }
  if (label >= 1000000000000000 / multiplier) {
    suffix = 'Q';
    if (bytes) {
      suffix = 'PB';
    }
    label = label / (1000000000000000.0 / multiplier);
  } else if (label >= 1000000000000 / multiplier) {
    suffix = 'T';
    if (bytes) {
      suffix = 'TB';
    }
    label = label / (1000000000000.0 / multiplier);
  } else if (label >= 1000000000 / multiplier) {
    suffix = 'B';
    if (bytes) {
      suffix = 'GB';
    }
    label = label / (1000000000.0 / multiplier);
  } else if (label >= 1000000 / multiplier) {
    suffix = 'M';
    if (bytes) {
      suffix = 'MB';
    }
    label = label / (1000000.0 / multiplier);
  } else if (label >= 1000 / multiplier) {
    suffix = 'K';
    if (bytes) {
      suffix = 'KB';
    }
    label = label / (1000.0 / multiplier);
  }
  if (round) {
    label = Math.round(label);
  } else {
    if (label === 0) {
      label = 0;
    } else if (label < 0.001) {
      label = label.toExponential(3);
    } else {
      label = Math.floor(label * 1000) / 1000;
    }
  }
  return [label, suffix];
}

export function convertUnits(valGB, units) {
  switch (units) {
    case 'PB':
      return valGB / 1000 / 1000;
    case 'TB':
      return valGB / 1000;
    case 'GB':
      return valGB;
    case 'MB':
      return valGB * 1000;
    case 'KB':
      return valGB * 1000 * 1000;
    case 'B':
      return valGB * 1000 * 1000 * 1000;
    default:
      return valGB;
  }
}

export function toBase(val, units, isBytes) {
  if (isBytes) {
    switch (units) {
      case 'PB':
        return val * 1000000000000000;
      case 'TB':
        return val * 1000000000000;
      case 'GB':
        return val * 1000000000;
      case 'MB':
        return val * 1000000;
      case 'KB':
        return val * 1000;
      case 'B':
        return val;
      default:
        return val;
    }
  }

  switch (units) {
    case 'Q':
      return val * 1000000000000000;
    case 'T':
      return val * 1000000000000;
    case 'B':
      return val * 1000000000;
    case 'M':
      return val * 1000000;
    case 'K':
      return val * 1000;
    case '':
      return val;
    default:
      return val;
  }
}

export class HtmlLegendPlugin {
  id: string = 'htmlLegend';
  graphData: GraphData = null;
  containerID: string = null;
  constructor(graphData: GraphData, containerID) {
    this.graphData = graphData;
    this.containerID = containerID;
  }

  afterUpdate = (chart, args, options) => {
    const legendContainer = document.getElementById(this.containerID);
    if (!legendContainer) {
      return;
    }

    // Remove old legend items
    while (legendContainer.firstChild) {
      legendContainer.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      let summary: Summary = this.graphData.summaryPerProvider[item.text];
      if (!summary) {
        return;
      }

      const divElement = document.createElement('div');
      divElement.className = 'legend-item';

      divElement.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      let colorElement = document.createElement('div');
      colorElement.className = 'legend-color';
      colorElement.style.backgroundColor = item.strokeStyle;
      divElement.appendChild(colorElement);

      let textContainerElement = document.createElement('div');
      textContainerElement.className = 'legend-item-text-container';

      let titleElement = document.createElement('div');
      titleElement.className = 'legend-item-title';
      titleElement.innerText = item.text;

      let valueElement = document.createElement('div');
      valueElement.className = 'legend-item-value';
      if (summary.value !== 'NaN') {
        valueElement.innerText = summary.value;
      } else if (this.graphData.isPercentage) {
        valueElement.innerText = Math.round(summary.percent) + '%';
      }

      textContainerElement.appendChild(titleElement);

      textContainerElement.appendChild(valueElement);

      if (!this.graphData.isPercentage) {
        let percentageElement = document.createElement('div');
        percentageElement.className = 'legend-item-percentage';
        percentageElement.innerText = summary.percent + '%';
        textContainerElement.appendChild(percentageElement);
      }

      divElement.appendChild(textContainerElement);
      legendContainer.appendChild(divElement);
    });
  };
}

export function getTopEntryVal(e: TopEntry, valName: string) {
  switch (valName) {
    case 'hits':
      return e.hits;
    case 'bytes':
      return e.bytes;
    default:
      return e.errors;
  }
}

export function topTotal(entries: TopEntry[], valName: string) {
  let total = 0;
  for (let entry of entries) {
    total += getTopEntryVal(entry, valName);
  }
  return total;
}

export function calcTopEntryPercentage(e: TopEntry, entries: TopEntry[], valName: string) {
  let totalVal = topTotal(entries, valName);
  if (!totalVal) {
    return 0;
  }

  return Math.round((getTopEntryVal(e, valName) / totalVal) * 100);
}
