import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'availabilityMonitoring',
  initialState: {
    availabilityOvertime: [],
    providerTotals: [],
    regionFailureShares: [],
    chosenGeos: [{ continent: null, country: null, subdivision: null }],
    chosenCheck: null,
    chosenProviders: [],
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
    pendingFilter: {
      chosenGeos: [{ continent: null, country: null, subdivision: null }],
      chosenPeriod: 'Hour',
      chosenProviders: [],
    },
    loading: true,
    loadingOvertime: false,
    loadingTotals: false,
    loadingShares: false,
  },
  reducers: {
    availabilityOvertimeReceived: (availabilityMonitoring, action) => {
      availabilityMonitoring.loadingOvertime = false;
      availabilityMonitoring.loading =
        availabilityMonitoring.loadingOvertime ||
        availabilityMonitoring.loadingTotals ||
        availabilityMonitoring.loadingShares;
      let data = action.payload[0];
      if (availabilityMonitoring.pendingFilter.chosenProviders.length > 0) {
        data = data.filter(
          (d) =>
            availabilityMonitoring.pendingFilter.chosenProviders.findIndex(
              (c) => c.id === d.service_provider_id,
            ) !== -1,
        );
      }

      let allTimeStamps = [];
      data.map((providerData) => {
        providerData.values.map((v) => {
          if (allTimeStamps.indexOf(v.timestamp) === -1) {
            allTimeStamps.push(v.timestamp);
          }
          return null;
        });
        return null;
      });

      allTimeStamps.sort();

      allTimeStamps.map((t) => {
        data.map((providerData) => {
          if (providerData.values.findIndex((v) => v.timestamp === t) === -1) {
            providerData.values.push({
              timestamp: t,
              value: null,
            });
          }
          return null;
        });
        return null;
      });

      data.map((providerData) => {
        providerData.values.sort((a, b) => {
          return a.timestamp - b.timestamp;
        });
        return null;
      });

      if (availabilityMonitoring.pendingFilter) {
        availabilityMonitoring.chosenGeos = availabilityMonitoring.pendingFilter.chosenGeos;
        availabilityMonitoring.chosenProviders =
          availabilityMonitoring.pendingFilter.chosenProviders;
        availabilityMonitoring.chosenPeriod = availabilityMonitoring.pendingFilter.chosenPeriod;
      }
      availabilityMonitoring.availabilityOvertime = data;
    },

    providerTotalsReceived: (availabilityMonitoring, action) => {
      availabilityMonitoring.loadingTotals = false;
      availabilityMonitoring.loading =
        availabilityMonitoring.loadingOvertime ||
        availabilityMonitoring.loadingTotals ||
        availabilityMonitoring.loadingShares;
      let data = action.payload[0];
      if (availabilityMonitoring.pendingFilter.chosenProviders.length > 0) {
        data = data.filter(
          (d) =>
            availabilityMonitoring.pendingFilter.chosenProviders.findIndex(
              (c) => c.id === d.service_provider_id,
            ) !== -1,
        );
      }
      availabilityMonitoring.providerTotals = data;
    },

    regionFailureSharesReceived: (availabilityMonitoring, action) => {
      availabilityMonitoring.loadingShares = false;
      availabilityMonitoring.loading =
        availabilityMonitoring.loadingOvertime ||
        availabilityMonitoring.loadingTotals ||
        availabilityMonitoring.loadingShares;
      let data = action.payload[0];
      if (availabilityMonitoring.pendingFilter.chosenProviders.length > 0) {
        data = data.filter(
          (d) =>
            availabilityMonitoring.pendingFilter.chosenProviders.findIndex(
              (c) => c.id === d.service_provider_id,
            ) !== -1,
        );
      }
      availabilityMonitoring.regionFailureShares = data;
    },

    filtersUpdated: (availabilityMonitoring, action) => {
      availabilityMonitoring.pendingFilter = action.payload;
      availabilityMonitoring.loadingOvertime = true;
      availabilityMonitoring.loadingTotals = true;
      availabilityMonitoring.loadingShares = true;
      availabilityMonitoring.loading = true;
    },
    updateRange: (availabilityMonitoring, action) => {
      availabilityMonitoring.startTime = action.payload.startTime;
      availabilityMonitoring.endTime = action.payload.endTime;
    },
    chooseCheck: (availabilityMonitoring, action) => {
      availabilityMonitoring.chosenCheck = action.payload;
    },
  },
});

export const {
  availabilityOvertimeReceived,
  providerTotalsReceived,
  regionFailureSharesReceived,
  filtersUpdated,
  updateRange,
  chooseCheck,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadAvailabilityOvertime = (s, startTime, endTime, geos, check_id) => {
  let geosJSON = JSON.stringify(geos);
  return apiCallBegan({
    url: `/api/v1/services/${s}/availability/locations/?from=${startTime}&to=${endTime}&geos=${geosJSON}&check_id=${check_id}`,
    onSuccess: availabilityOvertimeReceived.type,
  });
};

export const loadProviderTotals = (s, startTime, endTime, geos, check_id) => {
  let geosJSON = JSON.stringify(geos);
  return apiCallBegan({
    url: `/api/v1/services/${s}/availability/provider-totals/?from=${startTime}&to=${endTime}&geos=${geosJSON}&check_id=${check_id}`,
    onSuccess: providerTotalsReceived.type,
  });
};

export const loadRegionFailureShares = (s, startTime, endTime, geos, check_id) => {
  let geosJSON = JSON.stringify(geos);
  return apiCallBegan({
    url: `/api/v1/services/${s}/availability/region-failure-shares/?from=${startTime}&to=${endTime}&geos=${geosJSON}&check_id=${check_id}`,
    onSuccess: regionFailureSharesReceived.type,
  });
};
