import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface GeoFencingCountry {
  country: string;
}

interface GeoFencing {
  id?: string;
  service: string;
  type: 'allow' | 'deny';
  countries: GeoFencingCountry[];
}

interface GeoFencingState {
  geoFencing: GeoFencing | null;
  updating: GeoFencing | null;
  error: string | null;
}

const initialState: GeoFencingState = {
  geoFencing: null,
  updating: null,
  error: null,
};

const slice = createSlice({
  name: 'geoFencing',
  initialState,
  reducers: {
    geoFencingReceived: (state, action) => {
      state.geoFencing = action.payload[0][0];
    },
    geoFencingAdded: (state, action) => {
      state.geoFencing = action.payload[0];
      state.updating = null;
      state.error = null;
    },
    geoFencingUpdated: (state, action) => {
      state.geoFencing = action.payload[0];
      state.updating = null;
      state.error = null;
    },
    geoFencingChangeErrorHappened: (state, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];
      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]][0];
      }
      state.error = msg;
    },
    editUpdatingState: (state, action) => {
      state.updating = action.payload;
      state.error = null;
    },
  },
});

export const {
  geoFencingReceived,
  editUpdatingState,
  geoFencingAdded,
  geoFencingUpdated,
  geoFencingChangeErrorHappened,
} = slice.actions;

export default slice.reducer;

export const loadGeoFencing = (service: string) =>
  apiCallBegan({
    url: `/api/v1/services/${service}/geo-restriction/`,
    onSuccess: geoFencingReceived.type,
  });

export const addGeoFencing = (service: string, a: GeoFencing) =>
  apiCallBegan({
    url: `/api/v1/services/${service}/geo-restriction/`,
    method: 'post',
    data: a,
    onSuccess: geoFencingAdded.type,
    onError: geoFencingChangeErrorHappened.type,
  });

export const updateGeoFencing = (id: string, service: string, a: GeoFencing) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${service}/geo-restriction/${id}/`,
    method: 'put',
    data: a,
    onSuccess: geoFencingUpdated.type,
    onError: geoFencingChangeErrorHappened.type,
  });
