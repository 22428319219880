import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { accountID } from '../middleware/api';

interface ProviderContract {
  provider: string;
  unit_price_tb: number;
}

export interface Contract {
  id: string;
  account_id: string;
  bucket_start_date: string;
  bucket_end_date: string;
  bucket_size_tb: number;
  providers_contracts: ProviderContract[];
}

interface ProvidersConsumption {
  provider: string;
  consumption_bytes: number;
}

export interface Invoice {
  id: string;
  invoice_date: string; // e.g. "2024-07-27"
  bucket_consumed_bytes: number;
  bucket_remained_bytes: number;
  contract: Contract | string;
  providers_consumption: ProvidersConsumption[];
  invoice_pdf: boolean;
}

interface BillingState {
  invoices: Invoice[];
  contracts: Contract[];
}

const initialState: BillingState = {
  invoices: [],
  contracts: [],
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    invoiceReceived: (state, action) => {
      state.invoices = action.payload[0];
    },
    contractsReceived: (state, action) => {
      state.contracts = action.payload[0];
    },
  },
});

export const { invoiceReceived, contractsReceived } = slice.actions;

export default slice.reducer;

export const loadInvoices = () =>
  apiCallBegan({
    url: `/api/v1/billing/invoices/`,
    onSuccess: invoiceReceived.type,
  });

export const loadContracts = () =>
  apiCallBegan({
    url: `/api/v1/billing/contracts/`,
    onSuccess: contractsReceived.type,
  });

export function downloadInvoicePdf(invoiceId: string, onError: Function) {
  fetch(`/api/v1/billing/invoices/${invoiceId}/download_invoice/`, {
    method: 'GET',
    headers: { 'X-Account-ID': accountID! },
  })
    .then((response) => {
      if (!response.ok) {
        onError();
        throw new Error(response.status.toString());
      }
      return response;
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    })
    .catch((error) => {
      console.log(error);
    });
}
