import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { createRoot } from 'react-dom/client';

const store = configureStore();

let root = createRoot(document.getElementById('root'));

if (
  document.location.toString().indexOf('/login') !== -1 ||
  document.location.toString().indexOf('/register') !== -1
) {
  document.body.className += 'login';
}
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
