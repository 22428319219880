import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface Resource {
  name: string;
  value: string;
}

export interface Channel {
  type: string;
  name: string;
}

export interface AlertHistoryEntry {
  id: string;
  account_id: string;
  timestamp: number;
  alert_name: string;
  alert_type: string;
  provider: string;
  service: string;
  threshold: number;
  resources: Resource[];
  channels: Channel[];
  message: string;
}

interface AlertHistoryState {
  alertHistory: AlertHistoryEntry[];
}

const initialState: AlertHistoryState = {
  alertHistory: [],
};

const slice = createSlice({
  name: 'alertHistory',
  initialState,
  reducers: {
    alertHistoryReceived: (state, action) => {
      const response = action.payload[0];
      state.alertHistory = response === null ? [] : response;
    },
  },
});

export const { alertHistoryReceived } = slice.actions;

export default slice.reducer;

export const loadAlertHistory = (accountId: string, start_time: number, end_time: number) =>
  apiCallBegan({
    url: `/api/v1/alert-sender/${accountId}/history?start_time=${start_time}&end_time=${end_time}`,
    onSuccess: alertHistoryReceived.type,
  });
