import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const HOUR_MILLIS = 60 * 60 * 1000;
const DAY_MILLIS = 24 * HOUR_MILLIS;

interface GraphData {
  allowed: number;
  blocked: number;
  serviceId: string;
  rules: string;
  logged: number;
  timestamp: number;
}

interface SampleLog {
  serviceId: string;
  timestamp: number;
  clientIP: string;
  url: string;
  method: string;
  action: string;
  country: string;
  customRuleActionsJSON: string;
  managedRulesScoresJSON: string;
  rateLimitRuleActionsJSON: string;
  headers: string;
}

interface SecurityMonitoringState {
  graphData: GraphData[];
  sampleLogs: SampleLog[];
  startTime: number;
  endTime: number;
  managed_ruleset_name: string | null;
  is_managed: boolean | null;
  chosenRulesOption: string;
  customRuleNames: string[];
  managedRuleGroups: string[];
  searchLogsText: string;
  chosenRuleGroup: string | null;
  sampleLogsPage: number;
  sampleLogsCount: number;
  sampleLogsLimit: number;
  loadedSampledLogs: boolean;
  sampledLogsError: string | null;
  graphError: string | null;
}

const initialState: SecurityMonitoringState = {
  graphData: [],
  sampleLogs: [],
  startTime: new Date().getTime() - DAY_MILLIS,
  endTime: new Date().getTime(),
  managed_ruleset_name: null,
  is_managed: null,
  chosenRulesOption: 'all',
  customRuleNames: [],
  managedRuleGroups: [],
  searchLogsText: '',
  chosenRuleGroup: '',
  sampleLogsPage: 1,
  sampleLogsCount: 1,
  sampleLogsLimit: 5,
  loadedSampledLogs: false,
  sampledLogsError: null,
  graphError: null,
};

const slice = createSlice({
  name: 'securityMonitoring',
  initialState,

  reducers: {
    logsReceived: (securityMonitoring, action) => {
      securityMonitoring.sampleLogs = JSON.parse(action.payload[0]).list;
      securityMonitoring.sampleLogsCount = action.payload[0].count;
      securityMonitoring.sampleLogsLimit = action.payload[0].limit;
      securityMonitoring.loadedSampledLogs = true;
      securityMonitoring.sampledLogsError = null;
    },

    graphDataReceived: (securityMonitoring, action) => {
      securityMonitoring.graphData = JSON.parse(action.payload[0]).points;
      securityMonitoring.graphError = null;
    },

    updateRulesOption: (securityMonitoring, action) => {
      if (action.payload.indexOf('custom') === 0) {
        securityMonitoring.is_managed = false;
        securityMonitoring.chosenRuleGroup = action.payload.replace('custom-', '');
        securityMonitoring.managed_ruleset_name = null;
      } else if (action.payload === 'all_managed') {
        securityMonitoring.managed_ruleset_name = null;
        securityMonitoring.chosenRuleGroup = 'managed';
      } else if (action.payload.indexOf('crs-') === 0) {
        securityMonitoring.chosenRuleGroup = action.payload.replace('crs-', '');
      } else {
        securityMonitoring.chosenRuleGroup = null;
      }
      securityMonitoring.chosenRulesOption = action.payload;
    },

    updateSearchLogsText: (securityMonitoring, action) => {
      securityMonitoring.searchLogsText = action.payload;
    },

    updateSampleLogsPage: (securityMonitoring, action) => {
      securityMonitoring.sampleLogsPage = action.payload;
    },

    updateRange: (securityMonitoring, action) => {
      securityMonitoring.startTime = action.payload.startTime;
      securityMonitoring.endTime = action.payload.endTime;
    },

    loadingSampledLogs: (securityMonitoring, action) => {
      securityMonitoring.loadedSampledLogs = false;
    },

    onSampledLogsError: (securityMonitoring, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      securityMonitoring.sampledLogsError = msg;
      securityMonitoring.loadedSampledLogs = true;
    },

    onGraphError: (securityMonitoring, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      securityMonitoring.graphError = msg;
    },
  },
});

export const {
  logsReceived,
  graphDataReceived,
  updateRulesOption,
  updateSearchLogsText,
  updateSampleLogsPage,
  updateRange,
  loadingSampledLogs,
  onSampledLogsError,
  onGraphError,
} = slice.actions;

export default slice.reducer;

export const loadWAFLogs = (s: string, search: string, page: number) => {
  let url = `/api/v1/events/waf-sample-logs/${s}/?search=${search}&page=${page}`;

  return apiCallBegan({
    url: url,
    onSuccess: logsReceived.type,
    onError: onSampledLogsError.type,
  });
};

export function downloadEventsCsv(s: string, search: string) {
  fetch(`/api/v1/events/waf-sample-logs/${s}/csv/?search=${search}`, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `events.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
}

export const loadWAFOvertime = (
  s: string,
  startTime: number,
  endTime: number,
  ruleGroupOption: string | null,
) => {
  let url = `/api/v1/events/waf-overtime/${s}/?startTime=${startTime}&endTime=${endTime}`;

  if (ruleGroupOption) {
    url += `&ruleGroupOption=${ruleGroupOption}`;
  }

  return apiCallBegan({
    url: url,
    onSuccess: graphDataReceived.type,
    onError: onGraphError.type,
  });
};
