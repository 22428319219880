import {
  formatValue,
  pointTrafficByServerGranularity,
  timeIntervalsConfig,
} from '../../views/service/serviceDashboard/GraphsUtils';
import { deepCopy } from '../../views/utils';

export let linesIncompletePeriods = {
  edgio: 25,
  akamai: 20,
  rest: 3,
};

export let linesApiGraphs = {
  edgio: ['Bytes'],
  akamai: ['Requests', 'Bytes', 'Percentage of Errors'],
  cloudfront: ['Requests', 'Bytes', 'Percentage of Errors'],
  rest: ['Requests', 'Bytes', 'Percentage of Errors', 'Cache Offload'],
};

export class Summary {
  title: string = '';
  percent: number = null;
  value: string = null;
  units: string = '';
  colors: GraphColors = new GraphColors();
  valueUnRounded = null;
  constructor(
    title: string,
    percent: number,
    value: string,
    units: string,
    colors: GraphColors,
    valueUnRounded: string,
  ) {
    this.title = title;
    this.percent = percent;
    this.value = value;
    this.units = units;
    this.colors = colors;
    this.valueUnRounded = valueUnRounded;
  }
}

export const mixedColors: string[] = ['#EBC2FE', '#DD94FF', '#C13DFF', '#9C31CE', '#8027A9'];

export class GraphColors {
  backgroundColor: string = '';
  foregroundColor: string = '';
  mapColors: string[] = [];
  constructor(back, fore, mapColors) {
    this.backgroundColor = back;
    this.foregroundColor = fore;
    this.mapColors = mapColors;
  }
}

export const colors: GraphColors[] = [
  new GraphColors('#3D5CFF', '#FFF', ['#D9E2FF', '#8EACFF', '#3D5CFF', '#141DE1', '#191D8F']),
  new GraphColors('#FF3D3D', '#FFF', ['#FFDFDF', '#FF9D9D', '#FF3D3D', '#C80D0D', '#881414']),
  new GraphColors('#FFD600', '#000', ['#FEFFC1', '#FFF441', '#FFD600', '#A67102', '#74470F']),
  new GraphColors('#28BB1B', '#FFF', ['#DEFDDB', '#8DF283', '#28BB1B', '#1B8312', '#155512']),
  new GraphColors('#B35F24', '#FFF', ['#F6E9CF', '#E2B667', '#D2812E', '#9B4722', '#68301F']),
];

export const holisticColor: GraphColors = new GraphColors('#FFD600', '#FFF', [
  '#FEFFC1',
  '#FFF441',
  '#FFD600',
  '#A67102',
  '#74470F',
]);
export const ioriverColor: GraphColors = new GraphColors('#A6E1FA', '#182574', []);

export class GraphData {
  title: string = '';
  valuesPerTimestamp = {};
  graphLines = {};
  summary: Summary[] = [];
  summaryPerProvider = {};
  providerTotals = {};
  total: number = null;
  totalTitle: string = null;
  totalStr: string = null;
  isPercentage: boolean = false;
  lineNames: string[] = [];
  timestamps: number[] = [];
  legendOnTop: boolean = false;
  units: string = '';
  colors = {};
  granularity: 'MINUTE' | 'HOUR' | 'DAY';
  showSummary: boolean = false;
  labels: [];
  stacked: boolean = false;
  sumToHundred: boolean = false;
  min: number = 0;
  max: number = undefined;

  constructor(lineNames, isPercentage) {
    this.lineNames = lineNames;
    for (let lineName of lineNames) {
      this.valuesPerTimestamp[lineName] = {};
      this.graphLines[lineName] = [];
      this.providerTotals[lineName] = 0;
    }
    this.isPercentage = isPercentage;
    if (isPercentage) {
      this.units = '%';
    }
  }

  key() {
    let key = this.title;
    if (this.lineNames.length > 0) {
      key += '-' + this.lineNames[0];
    }
    return key;
  }
  addPoint(timestamp, value, lineName, granularity, numMinutes, nonPerMinute) {
    if ((!value && value !== 0) || value === 'NaN') {
      return;
    }
    value = parseFloat(value);
    this.timestamps.push(timestamp);
    this.timestamps = Array.from(new Set(this.timestamps));
    if (!this.valuesPerTimestamp[lineName]) {
      return;
    }
    this.valuesPerTimestamp[lineName][timestamp] = value;
    if (this.isPercentage) {
      this.total += value;
      this.providerTotals[lineName] += value;
    } else {
      if (!nonPerMinute) {
        let byGranularity = pointTrafficByServerGranularity(value, granularity, numMinutes);
        this.total += byGranularity;
        this.providerTotals[lineName] += byGranularity;
      } else {
        this.total += value;
        this.providerTotals[lineName] += value;
      }
    }
  }

  prepare() {
    if (this.sumToHundred) {
      this.normalize();
    }
    if (!this.isPercentage) {
      if (this.total) {
        this.totalStr = formatValue(this.total, false, this.units === 'BPM').join('');
      } else {
        this.totalStr = '0';
      }
    }
    for (let lineName of new Set(this.lineNames)) {
      this.calculateLine(lineName);
      let percent = 0;
      let total = undefined;
      let totalStr = '';
      let totalStrUnRounded = '';
      if (!this.isPercentage) {
        if (this.total > 0) {
          percent = Math.round((this.providerTotals[lineName] * 100 * 100) / this.total) / 100;
          if (!percent) {
            percent = 0;
          }
          total = this.providerTotals[lineName];
          if (!total) {
            total = 0;
          }
        } else {
          totalStr = '0';
        }
      } else if (!this.isEmptyGraph(lineName)) {
        percent = this.lineTotal(lineName) / this.lineCount(lineName);
      }

      if (total) {
        totalStr = formatValue(total, true, this.units === 'BPM').join('');
        totalStrUnRounded = formatValue(total, false, this.units === 'BPM').join('');
      }
      this.summaryPerProvider[lineName] = new Summary(
        lineName,
        percent,
        totalStr,
        this.units,
        this.colors[lineName],
        totalStrUnRounded,
      );
    }
    this.summary = [];
    for (let val of Object.values(this.summaryPerProvider)) {
      this.summary.push(val);
    }
  }

  normalize() {
    for (let timestamp of this.timestamps) {
      let values = {};
      for (let lineName of this.lineNames) {
        let val = this.valuesPerTimestamp[lineName][timestamp];
        values[lineName] = val || 0;
      }
      let sum = 0;
      for (const [lineName, value] of Object.entries(values)) {
        sum += value;
      }

      if (sum < 100) {
        let remainder = 100 - sum;
        let max = 0;
        let maxLine;
        for (const [lineName, value] of Object.entries(values)) {
          if (value > max) {
            max = value;
            maxLine = lineName;
          }
        }
        if (maxLine) {
          this.valuesPerTimestamp[maxLine][timestamp] += remainder;
        }
      } else if (sum > 100) {
        let remainder = sum - 100;
        let max = 0;
        let maxLine;
        for (const [lineName, value] of Object.entries(values)) {
          if (value > max) {
            max = value;
            maxLine = lineName;
          }
        }
        if (maxLine) {
          this.valuesPerTimestamp[maxLine][timestamp] -= remainder;
        }
      }
    }
  }

  calculateLine(provider) {
    this.graphLines[provider] = [];
    this.timestamps.sort();
    for (let timestamp of this.timestamps) {
      let value = this.valuesPerTimestamp[provider][timestamp];
      if (value) {
        value = parseFloat(value);
      }
      this.graphLines[provider].push(value);
    }
  }

  isEmptyGraph(provider) {
    return !this.graphLines[provider] || this.graphLines[provider].findIndex((p) => p) === -1;
  }

  numOfSamples(provider) {
    let graphLine = this.graphLines[provider];
    let defined = graphLine.filter((p) => p !== undefined);
    return defined.length;
  }

  addTimestamps(timestamps) {
    this.timestamps.push(...timestamps);
    this.timestamps = Array.from(new Set(this.timestamps));
  }

  rate(): string {
    return 'Per Minute';
  }

  calculateLabels(isUTC: boolean) {
    let timeInterval;
    switch (this.granularity) {
      case 'MINUTE':
        timeInterval = 'Hour';
        break;
      case 'HOUR':
        timeInterval = 'Day';
        break;
      case 'DAY':
        timeInterval = 'Month';
        break;
    }

    let intervalConfig = timeIntervalsConfig[timeInterval];

    let timestamps = Array.from(new Set(this.timestamps));
    timestamps.sort();

    this.labels = timestamps.map((t) => {
      return intervalConfig.timestampToLable(t, isUTC);
    });
  }

  lineTotal(lineName) {
    let points = [...this.graphLines[lineName]].reverse();
    let seenNonZero = false;
    let total = 0;
    for (let point of points) {
      if (point) {
        seenNonZero = true;
      }

      if (seenNonZero && point !== null && point !== undefined) {
        point = parseFloat(point);
        total += point;
      }
    }
    return total;
  }

  lineCount(lineName) {
    let points = [...this.graphLines[lineName]].reverse();
    let seenNonZero = false;
    let count = 0;
    for (let point of points) {
      if (point) {
        seenNonZero = true;
      }

      if ((!point && !seenNonZero) || point === undefined) {
        continue;
      }
      count++;
    }
    return count;
  }
}

export class TopEntry {
  metric_value: string = '';
  hits: number = 0;
  bytes: number = 0;
  errors: number = 0;
}

export class TopMetric {
  entries: TopEntry[] = [];
  metric_name: string = '';
}

export class BasicSample {
  hits: number;
  bytes: number;
  numMinutes: number;
  cachedHitsPercentage: number;
  cachedBytesPercentage: number;
  errorsPercentage: number;
}

export class BasicPerValue extends Map<string, BasicSample> {}

export class ExtendedSample {
  statusCodes = {};
  httpVersion = {};
}

export class Point {
  timestamp: number;
  basic = {};
  extended: ExtendedSample = new ExtendedSample();
}

export class Metrics {
  hits: number;
  bytes: number;
  cachedHitsPercentage: number;
  cachedBytesPercentage: number;
  errorsPercentage: number;
  numMinutes: number;
  constructor() {
    this.hits = 0;
    this.bytes = 0;
  }
}

export function adjust(metrics: Metrics, granularity: 'MINUTE' | 'HOUR' | 'DAY') {
  metrics.hits = adjusted(metrics.hits, granularity);
  metrics.bytes = adjusted(metrics.bytes, granularity);
  metrics.adjusted = true;
}

export function adjusted(num, granularity: 'MINUTE' | 'HOUR' | 'DAY') {
  switch (granularity) {
    case 'HOUR':
      return num / 60;
    case 'DAY':
      return num / 1440;
  }
  return num;
}

export function sumMetrics(m1: Metrics, m2: Metrics): Metrics {
  if (!m1) {
    return deepCopy(m2);
  }

  let m = deepCopy(m1);
  m.hits += m2.hits;
  m.bytes += m2.bytes;
  return m;
}

/*
Server model
 */
export class ProviderMetrics {
  providerName: string;

  geo: string;

  advancedMetricName: string;

  advancedMetricValue: string;

  metrics: Metrics;
}

export class ServerPoint {
  timestamp: number;

  metrics: ProviderMetrics[];
}

export class ServiceStats {
  serviceID: string;
  points: ServerPoint[];
}

export class TrafficResponse {
  serviceStats: ServiceStats[];
  granularity: string;
}

export class MetricsPoint {
  timestamp: number;
  metrics: Metrics;
}

export class ProviderPoints {
  provider: string;
  points: MetricsPoint[];
}

export class AccountTrafficResponse {
  points: MetricsPoint[];
  providerPoints: ProviderPoints[];
  granularity: string;
  accountID: string;
}
