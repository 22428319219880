import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { mergeDeep } from '../../views/utils';
import {
  AccountTrafficResponse,
  adjust,
  colors,
  GraphData,
  ProviderPoints,
} from '../model/trafficStatsModels';
import { PayloadAction } from '@reduxjs/toolkit';

export function createEmptyGraphData(providers, isPercentage, showSummary = true): GraphData {
  let graphData = new GraphData(providers, isPercentage);

  graphData.showSummary = showSummary;
  if (isPercentage) {
    graphData.max = 100;
  }
  return graphData;
}

function prepareGraphData(
  providersPoints: ProviderPoints[],
  granularity,
  isUTC,
  accountID,
): Object {
  let graphNames = ['hits', 'bytes', 'cachedHits', 'cachedBytes', 'errors'];

  let percentageGraphs = {
    cachedHits: true,
    cachedBytes: true,
    edgeCachedHits: true,
    edgeCachedBytes: true,
    errors: true,
  };
  let timestamps = [];
  let graphData = {};
  let providers = [];
  for (let providerPoints of providersPoints) {
    providers.push(providerPoints.provider);
  }

  for (let providerPoints of providersPoints) {
    let accountData = graphData[accountID];
    if (!accountData) {
      accountData = {};
      graphData[accountID] = accountData;
      for (let graphName of graphNames) {
        accountData[graphName] = createEmptyGraphData(providers, percentageGraphs[graphName]);
      }
    }

    accountData['hits'].title = 'Requests';
    accountData['hits'].legendOnTop = true;
    accountData['hits'].units = 'RPM';
    accountData['bytes'].title = 'Bytes';
    accountData['bytes'].legendOnTop = true;
    accountData['bytes'].units = 'BPM';
    accountData['errors'].title = 'Percentage of Errors';
    accountData['errors'].legendOnTop = true;
    accountData['errors'].units = '%';
    accountData['errors'].max = undefined;
    accountData['cachedHits'].title = 'Cache Offload';
    accountData['cachedHits'].legendOnTop = true;
    accountData['cachedHits'].units = '%';
    accountData['cachedBytes'].title = 'Cache Offload';
    accountData['cachedBytes'].legendOnTop = true;
    accountData['cachedBytes'].units = '%';

    for (let point of providerPoints.points) {
      timestamps.push(point.timestamp);
      accountData['hits'].addPoint(
        point.timestamp,
        point.metrics.hits,
        providerPoints.provider,
        granularity,
        point.metrics.numMinutes,
        false,
      );

      accountData['bytes'].addPoint(
        point.timestamp,
        point.metrics.bytes,
        providerPoints.provider,
        granularity,
        point.metrics.numMinutes,
        false,
      );

      accountData['cachedHits'].addPoint(
        point.timestamp,
        point.metrics.cachedHitsPercentage,
        providerPoints.provider,
        granularity,
        point.metrics.numMinutes,
        true,
      );

      accountData['cachedBytes'].addPoint(
        point.timestamp,
        point.metrics.cachedBytesPercentage,
        providerPoints.provider,
        granularity,
        point.metrics.numMinutes,
        true,
      );

      accountData['errors'].addPoint(
        point.timestamp,
        point.metrics.errorsPercentage,
        providerPoints.provider,
        granularity,
        point.metrics.numMinutes,
        true,
      );
    }
  }

  timestamps = Array.from(new Set(timestamps));

  for (let accountId of Object.keys(graphData)) {
    for (let graphName of graphNames) {
      let graph = graphData[accountId][graphName];
      graph.granularity = granularity;
      graph.addTimestamps(timestamps);
      graph.prepare();
      graph.calculateLabels(isUTC);

      let count = 0;
      for (let lineName of graph.lineNames) {
        graph.colors[lineName] = colors[count];
        count++;
      }
    }
  }

  return graphData;
}

const slice = createSlice({
  name: 'accountStats',
  initialState: {
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
    filterByGeo: null,
    timestamps: [],
    graphsData: {},
    currentGranularity: 'MINUTE',
    isUTC: false,
    incompleteData: {},
  },
  reducers: {
    trafficReceived: (serviceTraffic, action: PayloadAction<AccountTrafficResponse>) => {
      let response: AccountTrafficResponse = action.payload[0];
      serviceTraffic.currentGranularity = response.granularity;
      for (let providerPoints of response.providerPoints) {
        for (let point of providerPoints.points) {
          point.timestamp /= 1000;
          adjust(point.metrics, action.payload[0].granularity);
        }
      }

      serviceTraffic.graphsData = prepareGraphData(
        response.providerPoints,
        response.granularity,
        serviceTraffic.isUTC,
        response.accountID,
      );
    },

    updateRange: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.totalCombined = {};
      serviceTraffic.startTime = Math.floor(action.payload.startTime / 60000) * 60000;
      serviceTraffic.endTime = Math.floor(action.payload.endTime / 60000) * 60000;
      serviceTraffic.isUTC = action.payload.isUTC;
      serviceTraffic.timestamps = [];
    },

    onError: (serviceTraffic, action) => {
      let msg = action.payload[0];

      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      serviceTraffic.error = msg;
    },
  },
});

export const { trafficReceived, updateRange, onError } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadAccountTraffic = (serviceIds, startTime, endTime) => {
  return apiCallBegan({
    url: `/api/v2/traffic/overtime/total/aggregated/${serviceIds}?startTime=${startTime}&endTime=${endTime}`,
    onSuccess: trafficReceived.type,
    onError: onError.type,
  });
};
