import axios from 'axios';
import * as actions from '../api';
import { loadAsyncTasks } from '../entities/asyncTasks';
import { toast } from 'react-toastify';

axios.defaults.withCredentials = true;

export let accountID = null;

let lastRequestTimes = {};

function areWeTestingWithJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (!action) {
      return;
    }
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onError, onBackgroundTaskFinished } =
      action.payload;

    if (
      accountID &&
      !RegExp('[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}').test(
        accountID,
      )
    ) {
      console.log('Redirecting back ' + url);
      document.location = '/';
      return;
    }

    if (
      (!method || method === 'GET') &&
      !areWeTestingWithJest() &&
      ((lastRequestTimes[url] && document.hidden) ||
        (lastRequestTimes[url] && new Date().getTime() - lastRequestTimes[url] < 5000))
    ) {
      return;
    }

    lastRequestTimes[url] = new Date().getTime();

    if (onStart) dispatch({ type: onStart });

    next(action);

    try {
      let headers = {
        'X-Account-ID':
          (url.indexOf('/api/v2/traffic/overtime/') !== -1 ||
            url.indexOf('/api/v1/top_stats/') !== -1) &&
          url.indexOf('f5910875-0b82-49ac-846b-3c440f215da7') !== -1
            ? '9f686afe-d524-40b2-b6cd-1427a89b4a21'
            : accountID,
      };

      if (
        (url.indexOf('/api/v2/traffic/overtime/') !== -1 ||
          url.indexOf('/api/v1/top_stats/') !== -1) &&
        url.indexOf('f5910875-0b82-49ac-846b-3c440f215da7') !== -1
      ) {
        headers['Authorization'] = 'Token jzaquigo2d5e11d9692c5e74be3024ba3a6022489d193d18';
      }

      const response = await axios.request({
        baseURL: '',
        url,
        method,
        data,
        withCredentials:
          url.indexOf('/api/v2/traffic/overtime/') === -1 &&
          url.indexOf('/api/v1/top_stats/') === -1,
        headers: headers,
      });

      if (onBackgroundTaskFinished) {
        let interval = setInterval(async () => {
          const asyncTasksResponse = await axios.request({
            baseURL: '',
            url: '/api/v1/async_tasks/',
            method: 'GET',
            withCredentials: true,
            headers: {
              'X-Account-ID': accountID,
            },
          });
          let task = asyncTasksResponse.data.find(
            (t) => t.id === parseInt(response.headers['x-background-task-id']),
          );
          if (task && (task.status === 'Status.COMPLETED' || task.status === 'Status.ERROR')) {
            window.clearInterval(interval);
            dispatch({
              type: onBackgroundTaskFinished,
              payload: [task.status === 'Status.COMPLETED', task.details],
            });
          }
        }, 200);
      }

      dispatch(actions.apiCallSuccess([response.data, action, response.headers]));

      // Specific
      if (onSuccess)
        dispatch({ type: onSuccess, payload: [response.data, action, response.headers] });
      if (
        (method === 'post' || method === 'put' || method === 'delete') &&
        url.indexOf('/api/v1/auth') !== 0 &&
        url.indexOf('/api/v1/request-account') !== 0
      ) {
        dispatch(loadAsyncTasks());
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        url.indexOf('/api/v1/auth/login') !== 0
      ) {
        localStorage.setItem('savedURL', document.location);
        document.location = '/login';
        return;
      }
      // General
      dispatch(actions.apiCallFailed([error.message, action]));
      if (method === 'delete') {
        let msg = error.message;
        const resp = error.response;

        if (resp && resp.data) {
          if (typeof resp.data === 'string' || resp.data instanceof String) {
            msg = resp.data;
          } else {
            msg = resp.data[Object.keys(resp.data)[0]];
          }
        }

        toast.error(msg);
      }
      // Specific
      if (onError) {
        let message = error.message;
        let resp = error.response;
        if (
          resp.status === 500 ||
          (resp.headers &&
            resp.headers['content-type'] &&
            resp.headers['content-type'].indexOf('text/html') !== -1)
        ) {
          message = 'Internal Server Error';
          resp = null;
        }
        dispatch({ type: onError, payload: [message, resp, action] });
      }
    }
  };

export default api;

export const setAccountID = (id) => {
  accountID = id;
};
