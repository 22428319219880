import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface RequestLogRule {
  id: string;
  service: string;
  name: string;
  destination: string;
  sampling_rate: number;
  path_pattern: string;
  methods: string;
  stage: string;
  log_body: boolean;
  destination_name: string;
}

type RequestLogEndpointType = 'AWS_S3';

interface RequestLogEndpoint {
  id: string;
  service: string;
  name: string;
  type: RequestLogEndpointType;
  domain: string;
  path: string;
  bucket_name: string;
}

interface AccessLogsState {
  rules: Array<RequestLogRule>;
  destinations: Array<RequestLogEndpoint>;
  updatingRule: RequestLogRule | null;
  updatingDestination: RequestLogEndpoint | null;
  error: string | null;
}

const initialState: AccessLogsState = {
  rules: [],
  destinations: [],
  updatingRule: null,
  updatingDestination: null,
  error: null,
};

const slice = createSlice({
  name: 'accessLogs',
  initialState,
  reducers: {
    rulesReceived: (accessLogs, action) => {
      accessLogs.rules = action.payload[0];
    },

    destinationsReceived: (accessLogs, action) => {
      accessLogs.destinations = action.payload[0];
    },

    ruleAdded: (accessLogs, action) => {
      accessLogs.rules.push(action.payload[0]);
      accessLogs.updatingRule = null;
    },

    destinationAdded: (accessLogs, action) => {
      accessLogs.destinations.push(action.payload[0]);
      accessLogs.updatingDestination = null;
    },

    ruleDeleted: (accessLogs, action) => {
      const deleted = action.payload[1].payload.id;
      accessLogs.rules = accessLogs.rules.filter((p) => p.id !== deleted);
    },

    destinationDeleted: (accessLogs, action) => {
      const deleted = action.payload[1].payload.id;
      accessLogs.destinations = accessLogs.destinations.filter((p) => p.id !== deleted);
    },

    ruleUpdateStarted: (accessLogs, action) => {
      accessLogs.updatingRule = action.payload;
    },

    destinationUpdateStarted: (accessLogs, action) => {
      accessLogs.updatingDestination = action.payload;
    },

    ruleUpdated: (accessLogs, action) => {
      const index = accessLogs.rules.findIndex((c) => c.id === action.payload[0].id);
      accessLogs.rules[index] = action.payload[0];
      accessLogs.updatingRule = null;
    },

    destinationUpdated: (accessLogs, action) => {
      const index = accessLogs.destinations.findIndex((c) => c.id === action.payload[0].id);
      accessLogs.destinations[index] = action.payload[0];
      accessLogs.updatingDestination = null;
    },

    onError: (accessLogs, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (typeof msg != 'string' && !(msg instanceof String)) {
          msg = JSON.stringify(msg[Object.keys(msg)[0]]);
        }
      }

      accessLogs.error = msg;
    },
  },
});

export const {
  rulesReceived,
  destinationsReceived,
  ruleAdded,
  destinationAdded,
  ruleDeleted,
  destinationDeleted,
  ruleUpdated,
  destinationUpdated,
  ruleUpdateStarted,
  destinationUpdateStarted,
  onError,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadRules = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/request-log-rules/`,
    onSuccess: rulesReceived.type,
  });

export const loadDestinations = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/request-log-endpoints/`,
    onSuccess: destinationsReceived.type,
  });

export const addRule = (s: string, r: RequestLogRule) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/request-log-rules/`,
    method: 'post',
    data: r,
    onError: onError.type,
    onSuccess: ruleAdded.type,
  });

export const addDestination = (s: string, d: RequestLogEndpoint) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/request-log-endpoints/`,
    method: 'post',
    data: d,
    onError: onError.type,
    onSuccess: destinationAdded.type,
  });

export const updateRule = (s: string, r: RequestLogRule, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/request-log-rules/${id}/`,
    method: 'put',
    data: r,
    onError: onError.type,
    onSuccess: ruleUpdated.type,
  });

export const updateDestination = (s: string, r: RequestLogEndpoint, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/request-log-endpoints/${id}/`,
    method: 'put',
    data: r,
    onError: onError.type,
    onSuccess: destinationUpdated.type,
  });

export const deleteRule = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/request-log-rules/${id}/`,
    method: 'delete',
    onError: onError.type,
    onSuccess: ruleDeleted.type,
  });

export const deleteDestination = (s: string, id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/request-log-endpoints/${id}/`,
    method: 'delete',
    onError: onError.type,
    onSuccess: destinationDeleted.type,
  });
