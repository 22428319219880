import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { OriginSet } from './originSets';

interface CustomRule {
  id: string;
  load_balancer: string;
  name: string;
  matching_rule: string;
  origin_set: string;
  priority: number;
  origin_set_name: string;
}

type LoadBalancerType = 'weighted';

export interface LoadBalancer {
  id: string;
  service: string;
  name: string;
  type: LoadBalancerType;
  origin_sets: OriginSet[];
  custom_rules: CustomRule[];
}

interface LoadBalancersState {
  list: LoadBalancer[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  pendingLoadBalancer: LoadBalancer | {};
  pendingCustomRules: CustomRule[];
  updating: boolean;
  updatingCustomRules: boolean;
  pendingCustomRulesLoadBalancerId: string | null;
}

const initialState: LoadBalancersState = {
  list: [],
  loading: false,
  lastFetch: null,
  error: null,
  pendingLoadBalancer: {},
  pendingCustomRules: [],
  updating: false,
  updatingCustomRules: false,
  pendingCustomRulesLoadBalancerId: null,
};

const slice = createSlice({
  name: 'loadBalancers',
  initialState,
  reducers: {
    // actions => action handlers

    loadBalancersReceived: (loadBalancers, action) => {
      loadBalancers.list = action.payload[0];
      loadBalancers.loading = false;
      loadBalancers.lastFetch = Date.now();
    },

    loadBalancerAdded: (loadBalancers, action) => {
      loadBalancers.list.push(action.payload[0]);
      loadBalancers.updating = false;
      loadBalancers.error = null;
    },

    loadBalancerUpdated: (loadBalancers, action) => {
      loadBalancers.list = loadBalancers.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      loadBalancers.updating = false;
      loadBalancers.error = null;
    },

    loadBalancerDeleted: (loadBalancers, action) => {
      const deleted = action.payload[1].payload.id;
      loadBalancers.list = loadBalancers.list.filter((p) => p.id !== deleted);
    },

    onError: (loadBalancers, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      loadBalancers.error = msg;
    },

    updatePendingLoadBalancer: (loadBalancers, action) => {
      loadBalancers.pendingLoadBalancer = action.payload;
      loadBalancers.updating = Object.keys(action.payload).length > 0;
      loadBalancers.error = null;
    },

    updatePendingCustomRules: (loadBalancers, action) => {
      loadBalancers.pendingCustomRules = action.payload.rules;
      loadBalancers.updatingCustomRules = action.payload.updating;
      if (action.payload.loadBalancerId) {
        loadBalancers.pendingCustomRulesLoadBalancerId = action.payload.loadBalancerId;
      }
    },

    loadBalancerCustomRulesUpdated: (loadBalancers, action) => {
      loadBalancers.list.map((content) => {
        if (content.id === action.payload[1].payload.id) {
          content.custom_rules = action.payload[1].payload.data.rules;
        }
        return null;
      });
      loadBalancers.updatingCustomRules = false;
      loadBalancers.error = null;
    },
  },
});

export const {
  loadBalancersReceived,
  loadBalancerAdded,
  loadBalancerUpdated,
  loadBalancerDeleted,
  onError,
  updatePendingLoadBalancer,
  updatePendingCustomRules,
  loadBalancerCustomRulesUpdated,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadLoadBalancers = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/load-balancers/`,
    onSuccess: loadBalancersReceived.type,
    onError: onError.type,
  });

export const addLoadBalancer = (s: string, o: LoadBalancer) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/load-balancers/`,
    method: 'post',
    data: o,
    onSuccess: loadBalancerAdded.type,
    onError: onError.type,
  });

export const updateLoadBalancer = (s: string, id: string, o: LoadBalancer) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/load-balancers/${id}/`,
    method: 'put',
    data: o,
    onSuccess: loadBalancerUpdated.type,
    onError: onError.type,
  });

export const updateLoadBalancerCustomRules = (s: string, id: string, cs: CustomRule[]) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/load-balancers/${id}/custom-rules/update_multiple/`,
    method: 'put',
    data: {
      rules: cs,
    },
    onSuccess: loadBalancerCustomRulesUpdated.type,
    onError: onError.type,
  });

export const deleteLoadBalancer = (s: string, o: string) =>
  apiCallBegan({
    id: o,
    url: `/api/v1/services/${s}/load-balancers/${o}/`,
    method: 'delete',
    onSuccess: loadBalancerDeleted.type,
    onError: onError.type,
  });
