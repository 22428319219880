import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { ServiceOrigin } from './serviceOrigins';

export interface OriginSet {
  id: string;
  service: string;
  name: string;
  origins: ServiceOrigin[];
}

interface OriginSetsState {
  list: OriginSet[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  pendingOriginSet: OriginSet | {};
  updating: boolean;
}

const initialState: OriginSetsState = {
  list: [],
  loading: false,
  lastFetch: null,
  error: null,
  pendingOriginSet: {},
  updating: false,
};

const slice = createSlice({
  name: 'originSets',
  initialState,
  reducers: {
    // actions => action handlers

    originSetsReceived: (originSets, action) => {
      originSets.list = action.payload[0];
      originSets.loading = false;
      originSets.lastFetch = Date.now();
    },

    originSetAdded: (originSets, action) => {
      originSets.list.push(action.payload[0]);
      originSets.pendingOriginSet = {};
      originSets.updating = false;
      originSets.error = null;
    },

    originSetUpdated: (originSets, action) => {
      originSets.list = originSets.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      originSets.updating = false;
      originSets.error = null;
    },

    originSetDeleted: (originSets, action) => {
      const deleted = action.payload[1].payload.id;
      originSets.list = originSets.list.filter((p) => p.id !== deleted);
    },

    onError: (originSets, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      originSets.error = msg;
    },

    updatePendingOriginSet: (originSets, action) => {
      originSets.pendingOriginSet = action.payload;
      originSets.updating = Object.keys(action.payload).length > 0;
      originSets.error = null;
    },
  },
});

export const {
  originSetsReceived,
  originSetAdded,
  originSetUpdated,
  originSetDeleted,
  onError,
  updatePendingOriginSet,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadOriginSets = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/origin-sets/`,
    onSuccess: originSetsReceived.type,
    onError: onError.type,
  });

export const addOriginSet = (s: string, o: OriginSet) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/origin-sets/`,
    method: 'post',
    data: o,
    onSuccess: originSetAdded.type,
    onError: onError.type,
  });

export const updateOriginSet = (s: string, id: string, o: OriginSet) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/origin-sets/${id}/`,
    method: 'put',
    data: o,
    onSuccess: originSetUpdated.type,
    onError: onError.type,
  });

export const deleteOriginSet = (s: string, o: string) =>
  apiCallBegan({
    id: o,
    url: `/api/v1/services/${s}/origin-sets/${o}/`,
    method: 'delete',
    onSuccess: originSetDeleted.type,
    onError: onError.type,
  });
