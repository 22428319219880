import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface Log {
  category: string;
  action: string;
  severity: string;
  text: string;
  customData: any | null;
  time: number;
  service_uid: string;
}

interface LogFilters {
  sortBy: string;
  desc: boolean;
  endTime: number;
  startTime: number;
  page: number;
}

interface LogsState {
  list: Log[];
  count: number;
  limit: number;
  loaded: boolean;
  filters: LogFilters;
  error?: string;
}

const HOUR_MILLIS = 60 * 60 * 1000;

const initialState: LogsState = {
  list: [],
  count: 0,
  limit: 1,
  loaded: false,
  filters: {
    sortBy: 'time',
    desc: true,
    endTime: new Date().getTime(),
    startTime: new Date().getTime() - HOUR_MILLIS,
    page: 1,
  },
};

const slice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    logsReceived: (logs, action) => {
      let data;
      if (typeof action.payload[0] === 'string') {
        data = JSON.parse(action.payload[0]);
      } else {
        data = action.payload[0];
      }
      logs.list = data.list;
      logs.count = data.count;
      logs.limit = data.limit;
      logs.loaded = true;
    },
    updateFilters: (logs, action) => {
      logs.filters = action.payload;
    },
    loading: (logs, action) => {
      logs.loaded = false;
    },

    onError: (logs, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      logs.error = msg;
      logs.loaded = true;
    },
  },
});

export const { logsReceived, updateFilters, loading, onError } = slice.actions;

export default slice.reducer;

// Action Creators

function createParams(filter: LogFilters) {
  let params = '';
  let sep = '?';
  for (const [key, value] of Object.entries(filter)) {
    if (value) {
      params += `${sep}${key}=${value}`;
      sep = '&';
    }
  }
  return params;
}

export const loadLogs = (s: string, filter: LogFilters) => {
  let url = `/api/v1/events/logs/${s}/${createParams(filter)}`;

  return apiCallBegan({
    url: url,
    onSuccess: logsReceived.type,
    onError: onError.type,
  });
};

export function downloadEventsCsv(s: string, filter: LogFilters) {
  fetch(`/api/v1/events/logs/${s}/csv${createParams(filter)}`, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `events.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode!.removeChild(link);
    });
}
