import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const IButton = (props) => {
  let variant = 'primary';
  if (props.variant) {
    variant = props.variant;
  }
  let type = 'button';
  if (props.type) {
    type = props.type;
  }

  return (
    <>
      <div
        className={
          'ibutton ' +
          props.size +
          ' ' +
          props.className +
          ' ' +
          (props.children ? '' : 'icon-only') +
          ' ' +
          variant
        }
        hidden={props.hidden}
        key={props.key}
        title={props.title}
      >
        <Button
          type={type}
          variant={props.variant}
          onClick={props.onClick}
          disabled={props.disabled ? 'disabled' : false}
          style={props.width ? { width: props.width + 'px' } : null}
          form={props.formID}
        >
          {props.icon && !props.iconRight && <img className="left-icon" src={props.icon} alt="" />}
          <div>{props.children}</div>
          {props.icon && props.iconRight && <img className="right-icon" src={props.icon} alt="" />}
        </Button>
      </div>
    </>
  );
};

IButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.elementType,
  type: PropTypes.any,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  iconRight: PropTypes.bool,
  icon: PropTypes.any,
  className: PropTypes.string,
  width: PropTypes.number,
  formID: PropTypes.string,
  hidden: PropTypes.bool,
  key: PropTypes.any,
  title: PropTypes.string,
};

export default IButton;
