import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

interface AuditLog {
  id: string;
  created: Date;
  type: string;
  email: string;
  scope: string;
  before: string;
  after: string;
  name: string | null;
}

interface AuditLogState {
  list: AuditLog[];
  count: number;
  limit: number;
  emails: string[];
  scopes: string[];
  types: string[];
}

const initialState: AuditLogState = {
  list: [],
  count: 0,
  limit: 1,
  emails: [],
  scopes: [],
  types: [],
};

const slice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    logsReceived: (logs, action) => {
      logs.list = action.payload[0].list;
      logs.count = action.payload[0].count;
      logs.limit = action.payload[0].limit;
      logs.emails = action.payload[0].emails;
      logs.scopes = action.payload[0].scopes;
      logs.types = action.payload[0].types;
    },
  },
});

interface AuditLogFilter {
  sortBy: string;
  emails: string[];
  scopes: string[];
  types: string[];
  desc: boolean;
  endTime: number;
  startTime: number;
  page: number;
}

export const { logsReceived } = slice.actions;

export default slice.reducer;

// Action Creators

function createParams(filter: AuditLogFilter) {
  let params = '';
  let sep = '?';
  for (const [key, value] of Object.entries(filter)) {
    if (value) {
      params += `${sep}${key}=${value}`;
      sep = '&';
    }
  }
  return params;
}

export const loadAuditLogs = (filter: AuditLogFilter) => {
  let url = `/api/v1/auth/account/audit-logs/${createParams(filter)}`;

  return apiCallBegan({
    url: url,
    onSuccess: logsReceived.type,
  });
};

export function downloadAuditLogsCsv(filter: AuditLogFilter) {
  fetch(`/api/v1/auth/account/audit-logs/download_audit_csv/${createParams(filter)}`, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `events.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
}
