import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CForm } from '@coreui/react';
import * as authActions from '../../../store/auth';
import logo from '../../../assets/login/logo.svg';
import FormTextField from '../../common/FormTextField';
import IButton from '../../common/IButton';
import { textDict } from '../../../utils/text-utils';

class ResetPassword extends React.Component {
  state = {
    isValid: false,
    data: {
      password: '',
      confirm_password: '',
    },
    loginError: null,
    sending: false,
  };

  allFieldsFilled = () => {
    for (const i in Object.values(this.state.data)) {
      if (Object.values(this.state.data)[i].trim() === '') {
        return false;
      }
    }
    return true;
  };

  handleChange = async ({ currentTarget: input }) => {
    const form = input.form;
    const isValid = form.checkValidity();

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ isValid, data });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    const { data } = this.state;
    const login = {
      token: token,
      password: data.password,
    };

    let sending = true;
    this.setState({ sending });

    this.props.resetPassword(login);
  };

  render() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (!token) {
      return <div style={{ color: 'black' }}>{textDict.invalidURL}</div>;
    }

    let loginError = this.props.auth.error;
    return (
      <div className="login-background">
        <div className="login-form-background">
          <div className="login-form-container">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <h1>{textDict.resetPasswordCreate}</h1>
            <CForm
              className="needs-validation"
              noValidate
              validated={this.state.isValid}
              onSubmit={this.handleSubmit}
            >
              <FormTextField
                name="password"
                type="password"
                placeholder={textDict.password}
                autocomplete="current-password"
                onchange={this.handleChange}
                required
                value={this.state.data.password}
              />
              <FormTextField
                name="confirm_password"
                type="password"
                label={textDict.registerConfirmPassword}
                onchange={this.handleChange}
                required
                value={this.state.data.confirm_password}
              />
              <IButton
                type="submit"
                disabled={!this.allFieldsFilled() || this.state.sending}
                variant="primary"
              >
                {textDict.resetPasswordSaveButton}
              </IButton>
              {loginError && (
                <div className="form-error-message" title={loginError}>
                  {loginError}
                </div>
              )}
            </CForm>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  auth: PropTypes.any,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (login) => dispatch(authActions.resetPassword(login)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
