import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { mergeDeep } from '../../views/utils';
import { GraphData } from '../model/trafficStatsModels';

function adjust(granularity, metrics) {
  switch (granularity) {
    case 'HOUR':
      metrics.hits /= 60;
      metrics.bytes /= 60;
      break;
    case 'DAY':
      metrics.hits /= 1440;
      metrics.bytes /= 1440;
      break;
  }
}

export function createEmptyGraphData(lineNames, isPercentage) {
  return new GraphData(lineNames, isPercentage);
}

function prepareGraphData(timestamps, combinedSeries, granularity) {
  let graphNames = [
    'hits',
    'bytes',
    'cachedHits',
    'cachedBytes',
    'errors',
    'statusCodesHits',
    'statusCodesBytes',
    '3xxHits',
    '3xxBytes',
    '4xxHits',
    '4xxBytes',
    'httpVersionHits',
    'httpVersionBytes',
  ];

  let percentageGraphs = {
    httpVersionHits: true,
    httpVersionBytes: true,
    statusCodesHits: true,
    statusCodesBytes: true,
    cachedHits: true,
    cachedBytes: true,
    errors: true,
    '3xxHits': true,
    '3xxBytes': true,
    '4xxHits': true,
    '4xxBytes': true,
  };

  let graphData = {};

  for (let timestamp of timestamps) {
    for (const [serviceId, points] of Object.entries(combinedSeries)) {
      let serviceData = graphData[serviceId];
      if (!serviceData) {
        serviceData = {};
        graphData[serviceId] = serviceData;
        serviceData['hits'] = createEmptyGraphData(['Total Hits'], false);
        serviceData['bytes'] = createEmptyGraphData(['BPM'], false);
        serviceData['cachedHits'] = createEmptyGraphData(['Cached Hits'], true);
        serviceData['cachedBytes'] = createEmptyGraphData(['Cached Bytes'], true);
        serviceData['errors'] = createEmptyGraphData(['Pct Errors'], true);
        serviceData['statusCodesHits'] = createEmptyGraphData(
          ['1xx', '2xx', '3xx', '4xx', '5xx'],
          true,
        );
        serviceData['statusCodesBytes'] = createEmptyGraphData(
          ['1xx', '2xx', '3xx', '4xx', '5xx'],
          true,
        );
        serviceData['3xxHits'] = createEmptyGraphData(['301', '302', '304', 'Other'], true);
        serviceData['3xxBytes'] = createEmptyGraphData(['301', '302', '304', 'Other'], true);

        serviceData['4xxHits'] = createEmptyGraphData(['400', '401', '403', '404', 'Other'], true);
        serviceData['4xxBytes'] = createEmptyGraphData(['400', '401', '403', '404', 'Other'], true);

        serviceData['httpVersionHits'] = createEmptyGraphData(
          ['HTTP/1', 'HTTP/1.1', 'HTTP/2', 'HTTP/3'],
          true,
        );
        serviceData['httpVersionBytes'] = createEmptyGraphData(
          ['HTTP/1', 'HTTP/1.1', 'HTTP/2', 'HTTP/3'],
          true,
        );

        serviceData['hits'].labelsConfig['Total Hits'] = {
          name: 'Total Hits',
          style: '#E7B403',
          color: '#E7B403',
          badgeColor: '#E7B403',
        };
        serviceData['bytes'].labelsConfig['BPM'] = {
          name: 'BPM',
          style: '#E7B403',
          color: '#E7B403',
          badgeColor: '#E7B403',
        };
        serviceData['cachedHits'].labelsConfig['Cached Hits'] = {
          name: 'Cached Hits',
          style: '#E7B403',
          color: '#E7B403',
          badgeColor: '#E7B403',
        };
        serviceData['cachedBytes'].labelsConfig['Cached Bytes'] = {
          name: 'Cached Bytes',
          style: '#E7B403',
          color: '#E7B403',
          badgeColor: '#E7B403',
        };

        serviceData['errors'].labelsConfig['Pct Errors'] = {
          name: 'Pct Errors',
          style: '#E7B403',
          color: '#E7B403',
          badgeColor: '#E7B403',
        };

        let statusCodesColors = {
          '1xx': '#E7B403',
          '2xx': '#FF3D3D',
          '3xx': '#00A854',
          '4xx': '#9A6F00',
          '5xx': '#0267FF',
        };

        for (let graphName of ['statusCodesHits', 'statusCodesBytes']) {
          for (let statusCodeSeries of ['1xx', '2xx', '3xx', '4xx', '5xx']) {
            serviceData[graphName].labelsConfig[statusCodeSeries] = {
              name: statusCodeSeries,
              style: statusCodesColors[statusCodeSeries],
              color: statusCodesColors[statusCodeSeries],
              badgeColor: statusCodesColors[statusCodeSeries],
            };
          }
        }

        let xx3CodesColors = {
          301: '#E7B403',
          302: '#FF3D3D',
          304: '#00A854',
          Other: '#9A6F00',
        };

        for (let graphName of ['3xxHits', '3xxBytes']) {
          for (let statusCodeSeries of ['301', '302', '304', 'Other']) {
            serviceData[graphName].labelsConfig[statusCodeSeries] = {
              name: statusCodeSeries,
              style: xx3CodesColors[statusCodeSeries],
              color: xx3CodesColors[statusCodeSeries],
              badgeColor: xx3CodesColors[statusCodeSeries],
            };
          }
        }

        let xx4CodesColors = {
          400: '#E7B403',
          401: '#FF3D3D',
          403: '#0267FF',
          404: '#00A854',
          Other: '#9A6F00',
        };

        for (let graphName of ['4xxHits', '4xxBytes']) {
          for (let statusCodeSeries of ['400', '401', '403', '404', 'Other']) {
            serviceData[graphName].labelsConfig[statusCodeSeries] = {
              name: statusCodeSeries,
              style: xx4CodesColors[statusCodeSeries],
              color: xx4CodesColors[statusCodeSeries],
              badgeColor: xx4CodesColors[statusCodeSeries],
            };
          }
        }

        let httpVersionColors = {
          'HTTP/1': '#E7B403',
          'HTTP/1.1': '#FF3D3D',
          'HTTP/2': '#0267FF',
          'HTTP/3': '#00A854',
        };
        for (let graphName of ['httpVersionHits', 'httpVersionBytes']) {
          for (let httpVersion of ['HTTP/1', 'HTTP/1.1', 'HTTP/2', 'HTTP/3']) {
            serviceData[graphName].labelsConfig[httpVersion] = {
              name: httpVersion,
              style: httpVersionColors[httpVersion],
              color: httpVersionColors[httpVersion],
              badgeColor: httpVersionColors[httpVersion],
            };
          }
        }
      }

      let point = points[timestamp];
      if (!point) {
        continue;
      }

      if (!point.basic) {
        continue;
      }

      let basic = point.basic;

      serviceData.hits.addPoint(timestamp, basic.hits, 'Total Hits', granularity, basic.numMinutes);

      serviceData.cachedHits.addPoint(
        timestamp,
        basic.cachedHitsPercentage,
        'Cached Hits',
        granularity,
        basic.numMinutes,
      );

      serviceData.bytes.addPoint(timestamp, basic.bytes, 'BPM', granularity, basic.numMinutes);
      serviceData.cachedBytes.addPoint(
        timestamp,
        basic.cachedBytesPercentage,
        'Cached Bytes',
        granularity,
        basic.numMinutes,
      );

      serviceData.errors.addPoint(
        timestamp,
        basic.errorsPercentage,
        'Pct Errors',
        granularity,
        basic.numMinutes,
      );

      if (!point.extended) {
        continue;
      }

      let statusCodes = point.extended.statusCodes;
      if (!statusCodes) {
        continue;
      }

      let allStatusCodeBytes = 0;
      let allStatusCodeHits = 0;

      for (const [code, metrics] of Object.entries(statusCodes)) {
        let codeParsed = parseInt(code);
        if (codeParsed && codeParsed < 100) {
          allStatusCodeHits += metrics.hits;
          allStatusCodeBytes += metrics.bytes;
        }
      }
      for (let statusCode of ['1', '2', '3', '4', '5']) {
        let statusCodeMetrics = statusCodes[statusCode];
        if (statusCodeMetrics) {
          serviceData.statusCodesHits.addPoint(
            timestamp,
            Math.round(((statusCodeMetrics.hits * 1.0) / allStatusCodeHits) * 100),
            statusCode + 'xx',
            granularity,
            1,
          );
          serviceData.statusCodesBytes.addPoint(
            timestamp,
            Math.round(((statusCodeMetrics.bytes * 1.0) / allStatusCodeBytes) * 100),
            statusCode + 'xx',
            granularity,
            1,
          );
        }
      }

      let xx4Types = [400, 401, 403, 404, 'other_4xx'];

      let xx3Types = [301, 302, 304, 'other_3xx'];
      let xx3Metrics = statusCodes['3'];
      if (xx3Metrics) {
        for (let xx3Code of xx3Types) {
          let codeMetrics = statusCodes[xx3Code + ''];
          if (xx3Code === 'other_3xx') {
            xx3Code = 'Other';
          }
          if (codeMetrics) {
            serviceData['3xxHits'].addPoint(
              timestamp,
              Math.round(((codeMetrics.hits * 1.0) / xx3Metrics.hits) * 100),
              xx3Code + '',
              granularity,
              1,
            );
            serviceData['3xxBytes'].addPoint(
              timestamp,
              Math.round(((codeMetrics.bytes * 1.0) / xx3Metrics.bytes) * 100),
              xx3Code + '',
              granularity,
              1,
            );
          }
        }
      }

      let xx4Metrics = statusCodes['4'];
      if (xx4Metrics) {
        for (let xx4Code of xx4Types) {
          let codeMetrics = statusCodes[xx4Code + ''];
          if (xx4Code === 'other_4xx') {
            xx4Code = 'Other';
          }
          if (codeMetrics) {
            serviceData['4xxHits'].addPoint(
              timestamp,
              Math.round(((codeMetrics.hits * 1.0) / xx4Metrics.hits) * 100),
              xx4Code + '',
              granularity,
              1,
            );
            serviceData['4xxBytes'].addPoint(
              timestamp,
              Math.round(((codeMetrics.bytes * 1.0) / xx4Metrics.bytes) * 100),
              xx4Code + '',
              granularity,
              1,
            );
          }
        }
      }

      if (!point.extended.httpVersion) {
        continue;
      }

      let allVersionHits = 0;
      let allVersionBytes = 0;

      for (const [version, metrics] of Object.entries(point.extended.httpVersion)) {
        allVersionHits += metrics.hits;
        allVersionBytes += metrics.bytes;
      }

      for (const [version, metrics] of Object.entries(point.extended.httpVersion)) {
        serviceData['httpVersionHits'].addPoint(
          timestamp,
          Math.round(((metrics.hits * 1.0) / allVersionHits) * 100),
          version,
          granularity,
          1,
        );
        serviceData['httpVersionBytes'].addPoint(
          timestamp,
          Math.round(((metrics.bytes * 1.0) / allVersionBytes) * 100),
          version,
          granularity,
          1,
        );
      }
    }
  }

  for (let graphName of graphNames) {
    for (let serviceId of Object.keys(combinedSeries)) {
      let graph = graphData[serviceId][graphName];
      graph.addTimestamps(timestamps);
    }
  }

  for (let graphName of graphNames) {
    for (let serviceId of Object.keys(combinedSeries)) {
      let graph = graphData[serviceId][graphName];
      graph.prepare();
    }
  }

  return graphData;
}

const slice = createSlice({
  name: 'serviceTotalTraffic',
  initialState: {
    totalCombined: {},
    timestamps: [],
    graphData: {},
    currentGranularity: 'MINUTE',
  },
  reducers: {
    trafficReceived: (serviceTotalTraffic, action) => {
      let combinedSeries = {};
      if (serviceTotalTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTotalTraffic.timestamps = [];
      }
      serviceTotalTraffic.currentGranularity = action.payload[0].granularity;
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              basic: {},
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          for (let aggregatedMetrics of point.metrics) {
            adjust(action.payload[0].granularity, aggregatedMetrics.metrics);
            perTimestamp['basic'] = aggregatedMetrics.metrics;
            serviceTotalTraffic.timestamps.push(timestamp);
          }
        }
      }
      serviceTotalTraffic.timestamps = Array.from(new Set(serviceTotalTraffic.timestamps));
      mergeDeep(serviceTotalTraffic.totalCombined, combinedSeries);
      serviceTotalTraffic.graphData = prepareGraphData(
        serviceTotalTraffic.timestamps,
        serviceTotalTraffic.totalCombined,
        serviceTotalTraffic.currentGranularity,
      );
    },

    statusCodesReceived: (serviceTotalTraffic, action) => {
      if (serviceTotalTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTotalTraffic.timestamps = [];
      }
      serviceTotalTraffic.currentGranularity = action.payload[0].granularity;
      let xx4Types = [400, 401, 403, 404];
      let xx3Types = [301, 302, 304];
      let combinedSeries = {};
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }
        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              extended: {
                statusCodes: {},
              },
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }
          for (let aggregatedMetrics of point.metrics) {
            let statusCodes = perTimestamp.extended.statusCodes;
            let statusCode = parseInt(aggregatedMetrics.advancedMetricValue);
            statusCodes[Math.floor(statusCode / 100) + ''] = aggregatedMetrics.metrics;
            if (statusCode >= 400 && statusCode < 500) {
              if (xx4Types.findIndex((x) => x === statusCode) !== -1) {
                statusCodes[statusCode] = aggregatedMetrics.metrics;
              } else {
                statusCodes['other_4xx'] = aggregatedMetrics.metrics;
              }
            } else if (statusCode >= 300 && statusCode < 400) {
              if (xx3Types.findIndex((x) => x === statusCode) !== -1) {
                statusCodes[statusCode] = aggregatedMetrics.metrics;
              } else {
                statusCodes['other_3xx'] = aggregatedMetrics.metrics;
              }
            }

            serviceTotalTraffic.timestamps.push(timestamp);
            adjust(action.payload[0].granularity, aggregatedMetrics.metrics);
          }
        }
      }
      serviceTotalTraffic.timestamps = Array.from(new Set(serviceTotalTraffic.timestamps));
      mergeDeep(serviceTotalTraffic.totalCombined, combinedSeries);
      serviceTotalTraffic.graphData = prepareGraphData(
        serviceTotalTraffic.timestamps,
        serviceTotalTraffic.totalCombined,
        serviceTotalTraffic.currentGranularity,
      );
    },

    httpVersionReceived: (serviceTotalTraffic, action) => {
      if (serviceTotalTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTotalTraffic.timestamps = [];
      }
      serviceTotalTraffic.currentGranularity = action.payload[0].granularity;
      let combinedSeries = {};

      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              extended: {
                httpVersion: {},
              },
            };
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          for (let aggregatedMetrics of point.metrics) {
            let httpVersion = perTimestamp.extended.httpVersion;
            httpVersion[aggregatedMetrics.advancedMetricValue] = aggregatedMetrics.metrics;
            serviceTotalTraffic.timestamps.push(timestamp);
            adjust(action.payload[0].granularity, aggregatedMetrics.metrics);
          }
        }
      }
      serviceTotalTraffic.timestamps = Array.from(new Set(serviceTotalTraffic.timestamps));
      mergeDeep(serviceTotalTraffic.totalCombined, combinedSeries);
      serviceTotalTraffic.graphData = prepareGraphData(
        serviceTotalTraffic.timestamps,
        serviceTotalTraffic.totalCombined,
        serviceTotalTraffic.currentGranularity,
      );
    },

    onError: (serviceTraffic, action) => {
      let msg = action.payload[0];

      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      serviceTraffic.error = msg;
    },
  },
});

export const { trafficReceived, onError, statusCodesReceived, httpVersionReceived } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadTotalTraffic = (service, startTime, endTime, geo) => {
  let geoParam = '';
  if (geo !== null && geo !== 'world') {
    geoParam = '&geo=' + geo;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/total/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam,
    onSuccess: trafficReceived.type,
    onError: onError.type,
  });
};

export const loadTotalStatusCodes = (service, startTime, endTime, geo) => {
  let geoParam = '';
  if (geo !== null && geo !== 'world') {
    geoParam = '&geo=' + geo;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/total/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam +
      '&advancedMetricName=status_code',
    onSuccess: statusCodesReceived.type,
    onError: onError.type,
  });
};

export const loadTotalHttpVersion = (service, startTime, endTime, geo) => {
  let geoParam = '';
  if (geo !== null && geo !== 'world') {
    geoParam = '&geo=' + geo;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/total/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam +
      '&advancedMetricName=http_version',
    onSuccess: httpVersionReceived.type,
    onError: onError.type,
  });
};
