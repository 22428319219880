import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const invoiceIcon = require('../../assets/icons/invoice.svg').default;
const monitorIcon = require('../../assets/icons/monitor.svg').default;
const trafficIcon = require('../../assets/icons/traffic.svg').default;

interface AlertType {
  [key: string]: {
    topic: string;
    type: string;
    restrictions: string[];
    thresholdUnits: 'GB' | '%';
    icon: string;
  };
}

export interface Alert {
  id: string;
  account: string;
  name: string;
  alert_type: string;
  enabled: boolean;
  channels: string[];
  threshold: number;
  restrict_to_services: boolean;
  services: string[];
  restrict_to_account_providers: boolean;
  account_providers: string[];
  restrict_to_health_checks: boolean;
  health_checks: string[];
  repeat_notification_interval: number;
}

interface AlertsState {
  alerts: Alert[];
  updating: Alert | null;
  error: string | null;
}

export const recurringTimeIntervals = {
  Minute: 1,
  Hour: 60,
  Day: 60 * 24,
};

export const alertTypes: AlertType = {
  billing_consumption: {
    topic: 'Billing',
    type: 'Consumption',
    restrictions: ['services', 'account_providers'],
    thresholdUnits: 'GB',
    icon: invoiceIcon,
  },
  traffic_error_percent_per_provider: {
    topic: 'Traffic',
    type: 'Percentage of Errors',
    restrictions: ['services', 'account_providers'],
    thresholdUnits: '%',
    icon: trafficIcon,
  },
  availability_percent: {
    topic: 'Monitoring',
    type: 'Availability',
    restrictions: ['services', 'health_checks'],
    thresholdUnits: '%',
    icon: monitorIcon,
  },
};

const initialState: AlertsState = {
  alerts: [],
  updating: null,
  error: null,
};

const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    alertsReceived: (state, action) => {
      state.alerts = action.payload[0];
    },
    editUpdatingState: (state, action) => {
      state.updating = action.payload;
      state.error = null;
    },
    alertAdded: (state, action) => {
      state.alerts.push(action.payload[0]);
      state.updating = null;
      state.error = null;
    },
    alertDeleted: (state, action) => {
      const deleted = action.payload[1].payload.id;
      state.alerts = state.alerts.filter((s) => {
        return s.id !== deleted;
      });
    },
    alertUpdated: (state, action) => {
      state.alerts = state.alerts.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
      state.updating = null;
      state.error = null;
    },
    alertChangeErrorHappened: (state, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      state.error = msg;
    },
  },
});

export const {
  alertsReceived,
  editUpdatingState,
  alertAdded,
  alertUpdated,
  alertDeleted,
  alertChangeErrorHappened,
} = slice.actions;

export default slice.reducer;

export const loadAlerts = () =>
  apiCallBegan({
    url: '/api/v1/alerts/',
    onSuccess: alertsReceived.type,
  });

export const addAlert = (a: Alert) =>
  apiCallBegan({
    url: '/api/v1/alerts/',
    method: 'post',
    data: a,
    onSuccess: alertAdded.type,
    onError: alertChangeErrorHappened.type,
  });

export const updateAlert = (id: string, a: Alert) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/alerts/${id}/`,
    method: 'put',
    data: a,
    onSuccess: alertUpdated.type,
    onError: alertChangeErrorHappened.type,
  });

export const deleteAlert = (id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/alerts/${id}/`,
    method: 'delete',
    onSuccess: alertDeleted.type,
  });

export const sendTestAlert = (id: string) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/alerts/${id}/test_sending/`,
    method: 'post',
  });
