// prettier-ignore
let crs = [
        {"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942100", "phase": "2", "t": "removeNulls", "msg": "'SQL Injection Attack Detected via libinjection'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.sql_injection_score=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942140", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942160", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects blind sqli tests using sleep() or benchmark()'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942170", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects SQL benchmark and sleep injection attempts including conditional queries'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942190", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MSSQL code execution and information gathering attempts'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942220", "phase": "2", "t": "urlDecodeUni", "msg": "'Looking for integer overflow attacks", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942230", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects conditional SQL injection attempts'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942240", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MySQL charset switch and MSSQL DoS attempts'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942250", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MATCH AGAINST", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942270", "phase": "2", "t": "urlDecodeUni", "msg": "'Looking for basic sql injection. Common attack string for mysql", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942280", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects Postgres pg_sleep injection", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942290", "phase": "2", "t": "urlDecodeUni", "msg": "'Finds basic MongoDB SQL injection attempts'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942320", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MySQL and PostgreSQL stored procedure/function injections'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942350", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MySQL UDF injection and other data/structure manipulation attempts'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942360", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects concatenated basic SQL injection and SQLLFI attempts'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942500", "phase": "2", "t": "urlDecodeUni", "msg": "'MySQL in-line comment detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942110", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl2=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942120", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942130", "phase": "2", "t": "replaceComments", "msg": "'SQL Injection Attack", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942150", "phase": "2", "t": "lowercase", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942180", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects basic SQL authentication bypass attempts 1/3'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942200", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MySQL comment-/space-obfuscated injections and backtick termination'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942210", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects chained SQL injection attempts 1/2'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942300", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects MySQL comments", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942310", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects chained SQL injection attempts 2/2'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942340", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects basic SQL authentication bypass attempts 3/3'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942361", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects basic SQL injection based on keyword alter or union'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942370", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects classic SQL injection probings 2/3'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942380", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942390", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942400", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942410", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942470", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942480", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942430", "phase": "2", "t": "urlDecodeUni", "msg": "'Restricted SQL Character Anomaly Detection (args)", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.sql_injection_score=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942440", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Comment Sequence Detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.sql_injection_score=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942450", "phase": "2", "t": "urlDecodeUni", "msg": "'SQL Hex Encoding Identified'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942510", "phase": "2", "t": "urlDecodeUni", "msg": "'SQLi bypass attempt by ticks or backticks detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942251", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects HAVING injections'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942490", "phase": "2", "t": "urlDecodeUni", "msg": "'Detects classic SQL injection probings 3/3'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942420", "phase": "2", "t": "urlDecodeUni", "msg": "'Restricted SQL Character Anomaly Detection (cookies)", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.sql_injection_score=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942431", "phase": "2", "t": "urlDecodeUni", "msg": "'Restricted SQL Character Anomaly Detection (args)", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.sql_injection_score=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942460", "phase": "2", "t": "urlDecodeUni", "msg": "'Meta-Character Anomaly Detection Alert - Repetitive Non-Word Characters'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl3=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942101", "phase": "2", "t": "removeNulls", "msg": "'SQL Injection Attack Detected via libinjection'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942511", "phase": "2", "t": "urlDecodeUni", "msg": "'SQLi bypass attempt by ticks detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942421", "phase": "2", "t": "urlDecodeUni", "msg": "'Restricted SQL Character Anomaly Detection (cookies)", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.sql_injection_score=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/4"]},
{"rules_file_name": "REQUEST-942-APPLICATION-ATTACK-SQLI", "id": "942432", "phase": "2", "t": "urlDecodeUni", "msg": "'Restricted SQL Character Anomaly Detection (args)", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.sql_injection_score=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-sqli", "OWASP_CRS", "capec/1000/152/248/66", "PCI/6.5.2", "paranoia-level/4"]},
{"rules_file_name": "REQUEST-903.9005-CPANEL-EXCLUSION-RULES", "id": "9005100", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": ["application-multi", "language-multi", "platform-apache", "attack-generic"]},
{"rules_file_name": "REQUEST-943-APPLICATION-ATTACK-SESSION-FIXATION", "id": "943100", "phase": "2", "t": "urlDecodeUni", "msg": "'Possible Session Fixation Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-fixation", "paranoia-level/1", "OWASP_CRS", "capec/1000/225/21/593/61"]},
{"rules_file_name": "REQUEST-943-APPLICATION-ATTACK-SESSION-FIXATION", "id": "943110", "phase": "2", "t": "lowercase", "msg": "'Possible Session Fixation Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-fixation", "paranoia-level/1", "OWASP_CRS", "capec/1000/225/21/593/61"]},
{"rules_file_name": "REQUEST-943-APPLICATION-ATTACK-SESSION-FIXATION", "id": "943120", "phase": "2", "t": "lowercase", "msg": "'Possible Session Fixation Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-fixation", "paranoia-level/1", "OWASP_CRS", "capec/1000/225/21/593/61"]},
{"rules_file_name": "RESPONSE-951-DATA-LEAKAGES-SQL", "id": "951100", "phase": "4", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.sql_error_match=1'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116/54"]},
{"rules_file_name": "REQUEST-930-APPLICATION-ATTACK-LFI", "id": "930100", "phase": "2", "t": "none", "msg": "'Path Traversal Attack (/../)'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.lfi_score=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-lfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/126"]},
{"rules_file_name": "REQUEST-930-APPLICATION-ATTACK-LFI", "id": "930110", "phase": "2", "t": "cmdLine", "msg": "'Path Traversal Attack (/../)'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.lfi_score=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-lfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/126"]},
{"rules_file_name": "REQUEST-930-APPLICATION-ATTACK-LFI", "id": "930120", "phase": "2", "t": "lowercase", "msg": "'OS File Access Attempt'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-lfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/126", "PCI/6.5.4"]},
{"rules_file_name": "REQUEST-930-APPLICATION-ATTACK-LFI", "id": "930130", "phase": "2", "t": "lowercase", "msg": "'Restricted File Access Attempt'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-lfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/126", "PCI/6.5.4"]},
{"rules_file_name": "REQUEST-931-APPLICATION-ATTACK-RFI", "id": "931100", "phase": "2", "t": "none", "msg": "'Possible Remote File Inclusion (RFI) Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-rfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/175/253"]},
{"rules_file_name": "REQUEST-931-APPLICATION-ATTACK-RFI", "id": "931110", "phase": "2", "t": "urlDecodeUni", "msg": "'Possible Remote File Inclusion (RFI) Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-rfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/175/253"]},
{"rules_file_name": "REQUEST-931-APPLICATION-ATTACK-RFI", "id": "931120", "phase": "2", "t": "none", "msg": "'Possible Remote File Inclusion (RFI) Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-rfi", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/175/253"]},
{"rules_file_name": "REQUEST-931-APPLICATION-ATTACK-RFI", "id": "931130", "phase": "2", "t": "none", "msg": "'Possible Remote File Inclusion (RFI) Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.rfi_parameter_%{MATCHED_VAR_NAME}=.%{tx.1}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-rfi", "OWASP_CRS", "capec/1000/152/175/253", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-949-BLOCKING-EVALUATION", "id": "949100", "phase": "2", "msg": "'Request Denied by IP Reputation Enforcement'", "logdata": "'Previous Block Reason", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-ip"]},
{"rules_file_name": "REQUEST-911-METHOD-ENFORCEMENT", "id": "911100", "phase": "2", "msg": "'Method is not allowed by policy'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-generic", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/274", "PCI/12.1"]},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006100", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetById=942230;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006110", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942370;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006120", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942370;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006130", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006140", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006150", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942130;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006155", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006160", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006170", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006200", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942370;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006210", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006220", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetById=942440;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006230", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942130;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006240", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetById=921151;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006300", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006310", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006315", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006320", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006330", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006340", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006400", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942370;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006410", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006420", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006500", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006510", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006600", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006700", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006710", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006900", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-XENFORO-ADMIN", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006901", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-XENFORO-ADMIN", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006910", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=931130;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006920", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetById=931130;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006930", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006940", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942370;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006950", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942440;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006960", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9006-XENFORO-EXCLUSION-RULES", "id": "9006970", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944100", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/137/6", "PCI/6.5.2", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944110", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944120", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944130", "phase": "2", "t": "lowercase", "msg": "'Suspicious Java class detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944200", "phase": "2", "msg": "'Magic bytes Detected", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944210", "phase": "2", "msg": "'Magic bytes Detected Base64 Encoded", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944240", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944250", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-944-APPLICATION-ATTACK-JAVA", "id": "944300", "phase": "2", "t": "none", "msg": "'Base64 encoded string matched suspicious keyword'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-rce", "OWASP_CRS", "capec/1000/152/248", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-934-APPLICATION-ATTACK-NODEJS", "id": "934100", "phase": "2", "t": "base64Decode", "msg": "'Node.js Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-javascript", "platform-multi", "attack-rce", "attack-injection-nodejs", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "RESPONSE-954-DATA-LEAKAGES-IIS", "id": "954100", "phase": "4", "t": "lowercase", "msg": "'Disclosure of IIS install location'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-iis", "platform-windows", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116"]},
{"rules_file_name": "RESPONSE-954-DATA-LEAKAGES-IIS", "id": "954110", "phase": "4", "t": "none", "msg": "'Application Availability Error'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-iis", "platform-windows", "attack-disclosure", "paranoia-level/1", "PCI/6.5.6", "OWASP_CRS", "capec/1000/118/116"]},
{"rules_file_name": "RESPONSE-954-DATA-LEAKAGES-IIS", "id": "954120", "phase": "4", "t": "none", "msg": "'IIS Information Leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-iis", "platform-windows", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-954-DATA-LEAKAGES-IIS", "id": "954130", "phase": "4", "t": "none", "msg": "'IIS Information Leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "tags": ["application-multi", "language-multi", "platform-iis", "platform-windows", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002100", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002120", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002130", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=931130;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002140", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002141", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002142", "phase": "1", "t": "none", "ctl": "ruleRemoveById=200003", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002143", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002150", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002160", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002200", "phase": "1", "t": "none", "ctl": "ruleRemoveById=920300", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002300", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002400", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-WORDPRESS-ADMIN", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002401", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-WORDPRESS-ADMIN", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002410", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002420", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002520", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002530", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002540", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002700", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002710", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002720", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002730", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002740", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002750", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002760", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002770", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002800", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002810", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=920272;REQUEST_BODY", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002820", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002830", "phase": "1", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9002-WORDPRESS-EXCLUSION-RULES", "id": "9002900", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942432;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933100", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933110", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933120", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933130", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933140", "phase": "2", "t": "none", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933200", "phase": "2", "t": "cmdLine", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933150", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933160", "phase": "2", "t": "none", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933170", "phase": "2", "t": "none", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933180", "phase": "2", "t": "none", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933210", "phase": "2", "t": "compressWhitespace", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933151", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933131", "phase": "2", "t": "urlDecodeUni", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933161", "phase": "2", "t": "none", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933111", "phase": "2", "t": "lowercase", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-933-APPLICATION-ATTACK-PHP", "id": "933190", "phase": "2", "t": "urlDecodeUni", "msg": "'PHP Injection Attack", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-injection-php", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-910-IP-REPUTATION", "id": "910120", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-RBL-LOOKUP", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-ip", "paranoia-level/1", "OWASP_CRS"]},
{"rules_file_name": "REQUEST-901-INITIALIZATION", "id": "901318", "phase": "1", "t": "hexEncode", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.ua_hash=%{MATCHED_VAR}'", "tags": []},
{"rules_file_name": "REQUEST-901-INITIALIZATION", "id": "901340", "phase": "1", "msg": "'Enabling body inspection'", "ctl": "forceRequestBodyVariable=On", "ver": "'OWASP_CRS/3.3.4'", "tags": ["paranoia-level/1"]},
{"rules_file_name": "REQUEST-901-INITIALIZATION", "id": "901410", "phase": "1", "t": "hexEncode", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'TX.sampling_rnd100=%{MATCHED_VAR}'", "tags": []},
{"rules_file_name": "REQUEST-901-INITIALIZATION", "id": "901420", "phase": "1", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'TX.sampling_rnd100=%{TX.sampling_rnd100}%{TX.1}'", "tags": []},
{"rules_file_name": "RESPONSE-952-DATA-LEAKAGES-JAVA", "id": "952100", "phase": "4", "t": "none", "msg": "'Java Source Code Leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-952-DATA-LEAKAGES-JAVA", "id": "952110", "phase": "4", "t": "none", "msg": "'Java Errors'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-java", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-953-DATA-LEAKAGES-PHP", "id": "953100", "phase": "4", "t": "none", "msg": "'PHP Information Leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-953-DATA-LEAKAGES-PHP", "id": "953110", "phase": "4", "t": "none", "msg": "'PHP source code leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-php", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-953-DATA-LEAKAGES-PHP", "id": "953120", "phase": "4", "t": "none", "msg": "'PHP source code leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "tags": ["application-multi", "language-php", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-950-DATA-LEAKAGES", "id": "950130", "phase": "4", "t": "none", "msg": "'Directory Listing'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116/54/127", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-950-DATA-LEAKAGES", "id": "950140", "phase": "4", "t": "none", "msg": "'CGI source code leakage'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl1=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-disclosure", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/116", "PCI/6.5.6"]},
{"rules_file_name": "RESPONSE-950-DATA-LEAKAGES", "id": "950100", "phase": "4", "t": "none", "msg": "'The Application Returned a 500-Level Status Code'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'ERROR'", "setvar": "'tx.anomaly_score_pl2=+%{tx.error_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-disclosure", "PCI/6.5.6", "paranoia-level/2", "OWASP_CRS", "capec/1000/152"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920100", "phase": "2", "t": "none", "msg": "'Invalid HTTP Request Line'", "logdata": "'%{request_line}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl1=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920120", "phase": "2", "t": "urlDecodeUni", "msg": "'Attempted multipart/form-data bypass'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920160", "phase": "1", "t": "none", "msg": "'Content-Length HTTP header is not numeric'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920170", "phase": "2", "t": "none", "msg": "'GET or HEAD Request with Body Content'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920171", "phase": "2", "t": "none", "msg": "'GET or HEAD Request with Transfer-Encoding'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920180", "phase": "2", "t": "none", "msg": "'POST without Content-Length or Transfer-Encoding headers'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920181", "phase": "2", "t": "none", "msg": "'Content-Length and Transfer-Encoding headers present.'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920190", "phase": "2", "t": "none", "msg": "'Range", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920210", "phase": "2", "t": "none", "msg": "'Multiple/Conflicting Connection Header Data Found'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl1=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920220", "phase": "2", "t": "none", "msg": "'URL Encoding Abuse Attack Attempt'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/267/72"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920240", "phase": "2", "t": "none", "msg": "'URL Encoding Abuse Attack Attempt'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/267/72"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920260", "phase": "2", "t": "none", "msg": "'Unicode Full/Half Width Abuse Attack Attempt'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl1=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-iis", "platform-windows", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153/267/72"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920270", "phase": "2", "t": "urlDecodeUni", "msg": "'Invalid character in request (null character)'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920280", "phase": "2", "t": "none", "msg": "'Request Missing a Host Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl1=+%{tx.warning_anomaly_score}'", "skipAfter": "END-HOST-CHECK", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920290", "phase": "2", "t": "none", "msg": "'Empty Host Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl1=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920310", "phase": "2", "t": "none", "msg": "'Request Has an Empty Accept Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'NOTICE'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920311", "phase": "2", "t": "none", "msg": "'Request Has an Empty Accept Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'NOTICE'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920330", "phase": "2", "t": "none", "msg": "'Empty User Agent Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'NOTICE'", "setvar": "'tx.anomaly_score_pl1=+%{tx.notice_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920340", "phase": "2", "t": "none", "msg": "'Request Containing Content", "ver": "'OWASP_CRS/3.3.4'", "severity": "'NOTICE'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920350", "phase": "2", "t": "none", "msg": "'Host header is a numeric IP address'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl1=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920470", "phase": "1", "t": "lowercase", "msg": "'Illegal Content-Type header'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153", "PCI/12.1"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920420", "phase": "2", "t": "none", "msg": "'Request content type is not allowed by policy'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.content_type=|%{tx.0}|'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153", "PCI/12.1"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920480", "phase": "1", "t": "lowercase", "msg": "'Request content type charset is not allowed by policy'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153", "PCI/12.1"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920530", "phase": "1", "t": "lowercase", "msg": "'Multiple charsets detected in content type header'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153", "PCI/12.1"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920430", "phase": "1", "t": "none", "msg": "'HTTP protocol version is not allowed by policy'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920440", "phase": "2", "t": "none", "msg": "'URL file extension is restricted by policy'", "logdata": "'%{TX.0}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.extension=.%{tx.1}/'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920500", "phase": "2", "t": "urlDecodeUni", "msg": "'Attempt to access a backup or working file'", "logdata": "'%{TX.0}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920450", "phase": "2", "t": "lowercase", "msg": "'HTTP header is restricted by policy (%{MATCHED_VAR})'", "logdata": "'Restricted header detected", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.header_name_%{tx.0}=/%{tx.0}/'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272", "PCI/12.1"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920600", "phase": "1", "t": "lowercase", "msg": "'Illegal Accept header", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920200", "phase": "2", "t": "none", "msg": "'Range", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920201", "phase": "2", "t": "none", "msg": "'Range", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920230", "phase": "2", "t": "none", "msg": "'Multiple URL Encoding Detected'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "setvar": "'tx.anomaly_score_pl2=+%{tx.warning_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/255/153/267/120", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920300", "phase": "2", "t": "none", "msg": "'Request Missing an Accept Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'NOTICE'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920271", "phase": "2", "t": "urlDecodeUni", "msg": "'Invalid character in request (non printable characters)'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920320", "phase": "2", "t": "none", "msg": "'Missing User Agent Header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'NOTICE'", "setvar": "'tx.anomaly_score_pl2=+%{tx.notice_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "PCI/6.5.10", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920121", "phase": "2", "t": "urlDecodeUni", "msg": "'Attempted multipart/form-data bypass'", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920341", "phase": "2", "t": "none", "msg": "'Request Containing Content Requires Content-Type header'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/2", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920272", "phase": "2", "t": "urlDecodeUni", "msg": "'Invalid character in request (outside of printable chars below ascii 127)'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920490", "phase": "1", "t": "none", "msg": "'Request header x-up-devcap-post-charset detected in combination with prefix \\'UP\\' to User-Agent'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["language-aspnet", "platform-windows", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920510", "phase": "1", "t": "none", "msg": "'Invalid Cache-Control request header'", "logdata": "'Invalid Cache-Control value in request found within %{MATCHED_VAR_NAME}", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "header-whitelist", "paranoia-level/3", "OWASP_CRS", "capec/1000/210/272"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920202", "phase": "2", "t": "none", "msg": "'Range", "logdata": "'%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'WARNING'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/4"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920273", "phase": "2", "t": "urlDecodeUni", "msg": "'Invalid character in request (outside of very strict set)'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl4=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/4"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920274", "phase": "2", "t": "urlDecodeUni", "msg": "'Invalid character in request headers (outside of very strict set)'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl4=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/4"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920275", "phase": "2", "t": "urlDecodeUni", "msg": "'Invalid character in request headers (outside of very strict set)'", "logdata": "'%{MATCHED_VAR_NAME}=%{MATCHED_VAR}'", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl4=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/1000/210/272", "paranoia-level/4"]},
{"rules_file_name": "REQUEST-920-PROTOCOL-ENFORCEMENT", "id": "920460", "phase": "2", "t": "lowercase", "msg": "'Abnormal character escapes in request'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl4=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/4", "OWASP_CRS", "capec/1000/153/267"]},
{"rules_file_name": "REQUEST-905-COMMON-EXCEPTIONS", "id": "905100", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": ["application-multi", "language-multi", "platform-apache", "attack-generic"]},
{"rules_file_name": "REQUEST-905-COMMON-EXCEPTIONS", "id": "905110", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": ["application-multi", "language-multi", "platform-apache", "attack-generic"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921110", "phase": "2", "t": "lowercase", "msg": "'HTTP Request Smuggling Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/33"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921120", "phase": "2", "t": "lowercase", "msg": "'HTTP Response Splitting Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/34"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921130", "phase": "2", "t": "lowercase", "msg": "'HTTP Response Splitting Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/34"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921140", "phase": "2", "t": "htmlEntityDecode", "msg": "'HTTP Header Injection Attack via headers'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/273"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921150", "phase": "2", "t": "htmlEntityDecode", "msg": "'HTTP Header Injection Attack via payload (CR/LF detected)'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/33"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921160", "phase": "1", "t": "lowercase", "msg": "'HTTP Header Injection Attack via payload (CR/LF and header-name detected)'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/33"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921190", "phase": "1", "t": "urlDecodeUni", "msg": "'HTTP Splitting (CR/LF in request filename detected)'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/210/272/220/34"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921200", "phase": "2", "t": "htmlEntityDecode", "msg": "'LDAP Injection Attack'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-ldap", "platform-multi", "OWASP_CRS", "capec/1000/152/248/136"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921421", "phase": "1", "t": "lowercase", "msg": "'Content-Type header", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/1", "OWASP_CRS", "capec/1000/255/153", "PCI/12.1"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921151", "phase": "2", "t": "htmlEntityDecode", "msg": "'HTTP Header Injection Attack via payload (CR/LF detected)'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/2", "OWASP_CRS", "capec/1000/210/272/220/33"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921422", "phase": "1", "t": "lowercase", "msg": "'Content-Type header", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/2", "OWASP_CRS", "capec/1000/255/153", "PCI/12.1"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921230", "phase": "1", "t": "none", "msg": "'HTTP Range Header detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/3", "OWASP_CRS", "capec/1000/210/272/220"]},
{"rules_file_name": "REQUEST-921-PROTOCOL-ATTACK", "id": "921170", "phase": "2", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'TX.paramcounter_%{MATCHED_VAR_NAME}=+1'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "paranoia-level/3", "OWASP_CRS", "capec/1000/152/137/15/460"]},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004100", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004110", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004130", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004200", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004300", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-DOKUWIKI-ADMIN", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004310", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-DOKUWIKI-ADMIN", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004320", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004370", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9004-DOKUWIKI-EXCLUSION-RULES", "id": "9004380", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-922-MULTIPART-ATTACK", "id": "922100", "phase": "2", "t": "none", "msg": "'Multipart content type global _charset_ definition is not allowed by policy'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-multipart-header", "OWASP_CRS", "capec/1000/255/153", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-922-MULTIPART-ATTACK", "id": "922110", "phase": "2", "t": "lowercase", "msg": "'Illegal MIME Multipart Header content-type", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-protocol", "OWASP_CRS", "capec/272/220", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-922-MULTIPART-ATTACK", "id": "922120", "phase": "2", "t": "lowercase", "msg": "'Content-Transfer-Encoding was deprecated by rfc7578 in 2015 and should not be used'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-deprecated-header", "OWASP_CRS", "capec/272/220", "paranoia-level/1"]},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003100", "phase": "2", "t": "none", "ctl": "ruleRemoveById=920440", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003105", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003110", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.allowed_request_content_type=%{tx.allowed_request_content_type} |text/vcard|'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003115", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003116", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003120", "phase": "2", "t": "none", "ctl": "ruleRemoveById=920440", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003121", "phase": "2", "t": "none", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003125", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=932000-932999;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003130", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.allowed_methods=%{tx.allowed_methods} PUT PATCH CHECKOUT COPY DELETE LOCK MERGE MKACTIVITY MKCOL MOVE PROPFIND PROPPATCH UNLOCK REPORT TRACE jsonp'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003140", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.allowed_methods=%{tx.allowed_methods} PUT DELETE'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003150", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=932150;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003155", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=942190;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003160", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=941120;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003300", "phase": "2", "t": "none", "ctl": "ruleRemoveById=941150", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003310", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetById=920370-920390;ARGS_COMBINED_SIZE", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003320", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.allowed_request_content_type=%{tx.allowed_request_content_type} |text/vcard|'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003321", "phase": "1", "t": "none", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003330", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.allowed_request_content_type=%{tx.allowed_request_content_type} |text/calendar|'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003331", "phase": "1", "t": "none", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003340", "phase": "2", "t": "none", "ctl": "ruleRemoveByTag=attack-injection-php", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003350", "phase": "2", "t": "none", "ctl": "ruleRemoveById=931130", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003400", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003410", "phase": "2", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9003-NEXTCLOUD-EXCLUSION-RULES", "id": "9003500", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932100", "phase": "2", "t": "none", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932105", "phase": "2", "t": "none", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932110", "phase": "2", "t": "none", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-windows", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932115", "phase": "2", "t": "none", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-windows", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932120", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "language-powershell", "platform-windows", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932130", "phase": "2", "t": "cmdLine", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932140", "phase": "2", "t": "cmdLine", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-windows", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932150", "phase": "2", "t": "none", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932160", "phase": "2", "t": "lowercase", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932170", "phase": "2", "t": "urlDecode", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932171", "phase": "2", "t": "urlDecodeUni", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932180", "phase": "2", "t": "lowercase", "msg": "'Restricted File Upload Attempt'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-rce", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932200", "phase": "2", "t": "urlDecodeUni", "msg": "'RCE Bypass Technique'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-rce", "paranoia-level/2", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932106", "phase": "2", "t": "none", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-932-APPLICATION-ATTACK-RCE", "id": "932190", "phase": "2", "t": "cmdLine", "msg": "'Remote Command Execution", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl3=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-shell", "platform-unix", "attack-rce", "OWASP_CRS", "capec/1000/152/248/88", "PCI/6.5.2", "paranoia-level/3"]},
{"rules_file_name": "REQUEST-913-SCANNER-DETECTION", "id": "913100", "phase": "2", "t": "lowercase", "msg": "'Found User-Agent associated with security scanner'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'ip.reput_block_reason=%{rule.msg}'", "expirevar": "'ip.reput_block_flag=%{tx.reput_block_duration}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-scanner", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/224/541/310", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-913-SCANNER-DETECTION", "id": "913110", "phase": "2", "t": "lowercase", "msg": "'Found request header associated with security scanner'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'ip.reput_block_reason=%{rule.msg}'", "expirevar": "'ip.reput_block_flag=%{tx.reput_block_duration}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-scanner", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/224/541/310", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-913-SCANNER-DETECTION", "id": "913120", "phase": "2", "t": "lowercase", "msg": "'Found request filename/argument associated with security scanner'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'ip.reput_block_reason=%{rule.msg}'", "expirevar": "'ip.reput_block_flag=%{tx.reput_block_duration}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-scanner", "paranoia-level/1", "OWASP_CRS", "capec/1000/118/224/541/310", "PCI/6.5.10"]},
{"rules_file_name": "REQUEST-913-SCANNER-DETECTION", "id": "913101", "phase": "2", "t": "lowercase", "msg": "'Found User-Agent associated with scripting/generic HTTP client'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'ip.reput_block_reason=%{rule.msg}'", "expirevar": "'ip.reput_block_flag=%{tx.reput_block_duration}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-scripting", "OWASP_CRS", "capec/1000/118/224/541/310", "PCI/6.5.10", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-913-SCANNER-DETECTION", "id": "913102", "phase": "2", "t": "lowercase", "msg": "'Found User-Agent associated with web crawler/bot'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'ip.reput_block_reason=%{rule.msg}'", "expirevar": "'ip.reput_block_flag=%{tx.reput_block_duration}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-reputation-crawler", "OWASP_CRS", "capec/1000/118/224/541/310", "PCI/6.5.10", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912120", "phase": "1", "msg": "'Denial of Service (DoS) attack identified from %{tx.real_ip} (%{tx.dos_block_counter} hits since last alert)'", "ver": "'OWASP_CRS/3.3.4'", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912130", "phase": "1", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'ip.dos_block_counter=+1'", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912140", "phase": "5", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "skipAfter": "END-DOS-PROTECTION-CHECKS", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912150", "phase": "5", "t": "lowercase", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'tx.extension=/%{TX.1}/'", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912160", "phase": "5", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912161", "phase": "5", "t": "none", "ver": "'OWASP_CRS/3.3.4'", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912170", "phase": "5", "t": "none", "msg": "'Potential Denial of Service (DoS) Attack from %{tx.real_ip} - # of Request Bursts", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'ip.dos_block=1'", "expirevar": "'ip.dos_block=%{tx.dos_block_timeout}'", "tags": ["application-multi", "language-multi", "platform-multi", "paranoia-level/1", "attack-dos", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-912-DOS-PROTECTION", "id": "912171", "phase": "5", "t": "none", "msg": "'Potential Denial of Service (DoS) Attack from %{tx.real_ip} - # of Request Bursts", "ver": "'OWASP_CRS/3.3.4'", "setvar": "'ip.dos_block=1'", "expirevar": "'ip.dos_block=%{tx.dos_block_timeout}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-dos", "paranoia-level/2", "OWASP_CRS", "capec/1000/210/227/469"]},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001110", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001112", "phase": "2", "t": "none", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001114", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001116", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001122", "phase": "2", "ctl": "ruleRemoveById=942430", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001124", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001126", "phase": "2", "ctl": "ruleRemoveById=942440", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001128", "phase": "2", "ctl": "ruleRemoveById=942440", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001140", "phase": "2", "ctl": "ruleRemoveTargetById=942130;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001170", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001200", "phase": "2", "ctl": "ruleRemoveTargetById=942410;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001202", "phase": "2", "ctl": "ruleRemoveTargetById=942410;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001204", "phase": "2", "ctl": "ruleRemoveTargetById=932110;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001206", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001208", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001210", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001212", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001214", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-903.9001-DRUPAL-EXCLUSION-RULES", "id": "9001216", "phase": "2", "ctl": "ruleRemoveTargetByTag=OWASP_CRS;ARGS", "ver": "'OWASP_CRS/3.3.4'", "tags": []},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941100", "phase": "2", "t": "removeNulls", "msg": "'XSS Attack Detected via libinjection'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941110", "phase": "2", "t": "removeNulls", "msg": "'XSS Filter - Category 1", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941120", "phase": "2", "t": "removeNulls", "msg": "'XSS Filter - Category 2", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941130", "phase": "2", "t": "removeNulls", "msg": "'XSS Filter - Category 3", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941140", "phase": "2", "t": "removeNulls", "msg": "'XSS Filter - Category 4", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941160", "phase": "2", "t": "removeNulls", "msg": "'NoScript XSS InjectionChecker", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941170", "phase": "2", "t": "removeNulls", "msg": "'NoScript XSS InjectionChecker", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941180", "phase": "2", "t": "removeNulls", "msg": "'Node-Validator Blacklist Keywords'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941190", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941200", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941210", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941220", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941230", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941240", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941250", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941260", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941270", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941280", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941290", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941300", "phase": "2", "t": "removeNulls", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941310", "phase": "2", "t": "jsDecode", "msg": "'US-ASCII Malformed Encoding XSS Filter - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-tomcat", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941350", "phase": "2", "t": "jsDecode", "msg": "'UTF-7 Encoding IE XSS - Attack Detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-internet-explorer", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941360", "phase": "2", "t": "none", "msg": "'JSFuck / Hieroglyphy obfuscation detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242/63"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941370", "phase": "2", "t": "compressWhitespace", "msg": "'JavaScript global variable found'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl1=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "attack-xss", "paranoia-level/1", "OWASP_CRS", "capec/1000/152/242/63"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941101", "phase": "2", "t": "removeNulls", "msg": "'XSS Attack Detected via libinjection'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941150", "phase": "2", "t": "removeNulls", "msg": "'XSS Filter - Category 5", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "OWASP_CRS", "capec/1000/152/242", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941320", "phase": "2", "t": "lowercase", "msg": "'Possible XSS Attack Detected - HTML Tag Handler'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "OWASP_CRS", "capec/1000/152/242/63", "PCI/6.5.1", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941330", "phase": "2", "t": "compressWhitespace", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "OWASP_CRS", "capec/1000/152/242", "PCI/6.5.1", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941340", "phase": "2", "t": "compressWhitespace", "msg": "'IE XSS Filters - Attack Detected'", "logdata": "'Matched Data", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "platform-multi", "attack-xss", "OWASP_CRS", "capec/1000/152/242", "PCI/6.5.1", "paranoia-level/2"]},
{"rules_file_name": "REQUEST-941-APPLICATION-ATTACK-XSS", "id": "941380", "phase": "2", "t": "none", "msg": "'AngularJS client side template injection detected'", "logdata": "'Matched Data", "ctl": "auditLogParts=+E", "ver": "'OWASP_CRS/3.3.4'", "severity": "'CRITICAL'", "setvar": "'tx.anomaly_score_pl2=+%{tx.critical_anomaly_score}'", "tags": ["application-multi", "language-multi", "attack-xss", "OWASP_CRS", "capec/1000/152/242/63", "paranoia-level/2"]}
    ];

export default crs;
