import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'performanceMonitoring',
  initialState: {
    performanceOvertime: [],
    providerTotals: [],
    regionFailureShares: [],
    chosenGeos: [{ continent: null, country: null, subdivision: null }],
    chosenProviders: [],
    chosenCheck: null,
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
    pendingFilter: {
      chosenGeos: [{ continent: null, country: null, subdivision: null }],
      chosenPeriod: 'Hour',
      chosenProviders: [],
    },
    chosenMetric: 'total_time',
  },
  reducers: {
    performanceOvertimeReceived: (performanceMonitoring, action) => {
      let data = action.payload[0];
      if (performanceMonitoring.pendingFilter.chosenProviders.length > 0) {
        data = data.filter(
          (d) =>
            performanceMonitoring.pendingFilter.chosenProviders.findIndex(
              (c) => c.id === d.service_provider_id,
            ) !== -1,
        );
      }

      let allTimeStamps = [];
      data.map((providerData) => {
        providerData.detailed_values.map((v) => {
          if (allTimeStamps.indexOf(v.timestamp) === -1) {
            allTimeStamps.push(v.timestamp);
          }
          return null;
        });
        return null;
      });

      allTimeStamps.sort();

      allTimeStamps.map((t) => {
        data.map((providerData) => {
          if (providerData.detailed_values.findIndex((v) => v.timestamp === t) === -1) {
            providerData.detailed_values.push({
              timestamp: t,
              values: null,
            });
          }
          return null;
        });
        return null;
      });

      data.map((providerData) => {
        providerData.detailed_values.sort((a, b) => {
          return a.timestamp - b.timestamp;
        });
        return null;
      });

      if (performanceMonitoring.pendingFilter) {
        performanceMonitoring.chosenGeos = performanceMonitoring.pendingFilter.chosenGeos;
        performanceMonitoring.chosenProviders = performanceMonitoring.pendingFilter.chosenProviders;
        performanceMonitoring.chosenPeriod = performanceMonitoring.pendingFilter.chosenPeriod;
      }
      performanceMonitoring.performanceOvertime = data;
    },

    providerTotalsReceived: (performanceMonitoring, action) => {
      let data = action.payload[0];
      if (performanceMonitoring.pendingFilter.chosenProviders.length > 0) {
        data = data.filter(
          (d) =>
            performanceMonitoring.pendingFilter.chosenProviders.findIndex(
              (c) => c.id === d.service_provider_id,
            ) !== -1,
        );
      }
      performanceMonitoring.providerTotals = data;
    },

    filtersUpdated: (performanceMonitoring, action) => {
      performanceMonitoring.pendingFilter = action.payload;
    },
    updateRange: (performanceMonitoring, action) => {
      performanceMonitoring.startTime = action.payload.startTime;
      performanceMonitoring.endTime = action.payload.endTime;
    },
    chooseCheck: (performanceMonitoring, action) => {
      performanceMonitoring.chosenCheck = action.payload;
    },

    chooseMetric: (performanceMonitoring, action) => {
      performanceMonitoring.chosenMetric = action.payload;
    },
  },
});

export const {
  performanceOvertimeReceived,
  providerTotalsReceived,
  filtersUpdated,
  updateRange,
  chooseCheck,
  chooseMetric,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadPerformanceOvertime = (s, startTime, endTime, geos, checkId) => {
  let geosJSON = JSON.stringify(geos);
  return apiCallBegan({
    url: `/api/v1/services/${s}/performance/locations/?from=${startTime}&to=${endTime}&geos=${geosJSON}&check_id=${checkId}`,
    onSuccess: performanceOvertimeReceived.type,
  });
};

export const loadProviderTotals = (s, startTime, endTime, geos, checkId) => {
  let geosJSON = JSON.stringify(geos);
  return apiCallBegan({
    url: `/api/v1/services/${s}/performance/provider-totals/?from=${startTime}&to=${endTime}&geos=${geosJSON}&check_id=${checkId}`,
    onSuccess: providerTotalsReceived.type,
  });
};
