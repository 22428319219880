import { combineReducers } from 'redux';
import entitiesReducer from './entities';
import authReducer from './auth';
import dashboardReducer from './ui/dashboard';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  entities: entitiesReducer,
  auth: authReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
