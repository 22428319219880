import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { accountID } from '../middleware/api';

type ServiceTemplateType = 'DEFAULT' | 'LIVE' | 'VOD';

export interface Service {
  id: string;
  account: string;
  name: string;
  description: string;
  certificate: string;
  service_uid: string;
  cname: string;
  read_only: boolean;
  service_template: ServiceTemplateType;
  enable_extended_statistics: boolean;
  modified: string;
}

interface ProviderServiceExists {
  exists: boolean;
  service_name: string;
}

interface ProtocolConfig {
  id: string;
  service: string;
  http2_enabled: boolean;
  http3_enabled: boolean;
  ipv6_enabled: boolean;
}

type ProtocolSettingsConfig = { [key: string]: ProtocolConfig };

interface ServiceState {
  list: Array<Service>;
  loading: boolean;
  lastFetch: number | null;
  adding: boolean;
  changeError: string | null;
  providerServiceExists: ProviderServiceExists | null;
  sortBy: string;
  asc: boolean;
  updatingServiceSettings: Service | null;
  protocolSettings: ProtocolSettingsConfig;
}

const initialState: ServiceState = {
  list: [],
  loading: false,
  lastFetch: null,
  adding: false,
  changeError: null,
  providerServiceExists: null,
  sortBy: 'name',
  asc: true,
  updatingServiceSettings: null,
  protocolSettings: {},
};

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // actions => action handlers

    servicesReceived: (services, action) => {
      services.list = action.payload[0];
      if (accountID !== null) {
        services.list = services.list.filter((s) => s.account === accountID);
      }
      services.loading = false;
      services.lastFetch = Date.now();
      services.list.sort((v1, v2) => {
        if (services.asc) {
          return v1['name'].localeCompare(v2['name']);
        } else {
          return v2['name'].localeCompare(v1['name']);
        }
      });
    },

    serviceAdded: (services, action) => {
      services.list.push(action.payload[0]);
      services.adding = false;
    },

    serviceUpdated: (services, action) => {
      services.list = services.list.map((content) =>
        content.id === action.payload[0].id ? action.payload[0] : content,
      );
    },

    serviceDeleted: (services, action) => {
      const deleted = action.payload[1].payload.id;
      services.list = services.list.filter((s) => s.id !== deleted);
    },

    serviceAddStarted: (services, action) => {
      services.adding = true;
      services.changeError = '';
    },

    serviceAddStopped: (behaviors, action) => {
      behaviors.adding = false;
    },

    serviceChangeErrorHappened: (behaviors, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
      }

      behaviors.changeError = msg;
    },

    providerServiceExistsAnswerReceived: (services, action) => {
      services.providerServiceExists = action.payload[0];
    },

    sort: (services, action) => {
      services.sortBy = action.payload['sortBy'];
      services.asc = action.payload['asc'];

      services.list.sort((v1, v2) => {
        if (services.asc) {
          return v1['name'].localeCompare(v2['name']);
        } else {
          return v2['name'].localeCompare(v1['name']);
        }
      });
    },

    protocolSettingsLoaded: (services, action) => {
      if (action.payload[0][0]) {
        services.protocolSettings[action.payload[1].payload.id] = action.payload[0][0];
      } else {
        services.protocolSettings[action.payload[1].payload.id] = action.payload[0];
      }
    },

    updateEditingServiceSettings: (services, action) => {
      services.updatingServiceSettings = action.payload;
      services.changeError = '';
    },

    updateProtocolSettings: (services, action) => {
      services.protocolSettings[action.payload[0]] = action.payload[1];
    },

    protocolSettingsUpdated: (services, action) => {
      services.updatingServiceSettings = null;
    },
  },
});

export const {
  servicesReceived,
  serviceAdded,
  serviceDeleted,
  serviceUpdated,
  serviceAddStarted,
  serviceAddStopped,
  serviceChangeErrorHappened,
  providerServiceExistsAnswerReceived,
  sort,
  protocolSettingsLoaded,
  updateEditingServiceSettings,
  protocolSettingsUpdated,
  updateProtocolSettings,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadServices = () => {
  if (!accountID) {
    return null;
  }
  return apiCallBegan({
    url: '/api/v1/services/',
    onSuccess: servicesReceived.type,
  });
};

export const loadProtocolSettings = (id: string) => {
  return apiCallBegan({
    url: `/api/v1/services/${id}/protocol-config/`,
    id: id,
    onSuccess: protocolSettingsLoaded.type,
  });
};

export const addService = (s: Service) =>
  apiCallBegan({
    url: `/api/v1/services/create_from_form/`,
    method: 'post',
    data: s,
    onSuccess: serviceAdded.type,
    onError: serviceChangeErrorHappened.type,
  });

export const updateService = (id: string, s: Service) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${id}/`,
    data: s,
    method: 'post',
    onSuccess: serviceUpdated.type,
  });

export const updateProtocolConfig = (id: string, p: ProtocolSettingsConfig) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${id}/protocol-config/`,
    data: p,
    method: 'post',
    onError: serviceChangeErrorHappened.type,
    onSuccess: protocolSettingsUpdated.type,
  });

export const deleteService = (s: string, force: boolean) =>
  apiCallBegan({
    id: s,
    url: `/api/v1/services/${s}/?force=${force}/`,
    method: 'delete',
    onSuccess: serviceDeleted.type,
  });

export const checkIfProviderServiceExists = (s: string, account_provider_id: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/provider_service_exists?account_provider_id=${account_provider_id}`,
    onSuccess: providerServiceExistsAnswerReceived.type,
  });
